import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import ArrowLeftIcon from 'src/Assets/Svg/arrow-left.svg?react'
import { colors, device, variables } from 'src/Styled/variables'
import CloseCross from 'src/Assets/Svg/close-cross.svg?react'

const CloseButton = styled.button`
  display: none;
  @media ${device.mobileMin} {
    width: 40px;
    height: 40px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 16px;
    &:hover {
      background-color: ${colors.greyBg};
      border-radius: 4px;
    }
  }
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 22px;
`
const HeaderTitle = styled.h4`
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
`

const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  @media ${device.mobileMin} {
    display: none;
  }
`
interface ReviewedTrackHeaderProps {
  onClose: () => void
}
export const ReviewedTrackHeader: FC<ReviewedTrackHeaderProps> = ({
  onClose,
}) => {
  const { t } = useTranslation()
  return (
    <HeaderContainer>
      <CloseButton data-testid='close-button' onClick={onClose}>
        <CloseCross width='16px' height='16px' />
      </CloseButton>
      <StyledArrowLeftIcon width={12} height={12} onClick={onClose} />
      <HeaderTitle>{t('reviewsPage.modalDetailsTitle')}</HeaderTitle>
    </HeaderContainer>
  )
}
