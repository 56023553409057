import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const Skeleton = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
`

export const SkeletonElement = styled.div`
  position: absolute;
  background: ${colors.greyStroke};
  border-radius: 8px;
`

export const SkeletonAvatar = styled(SkeletonElement)`
  top: 5px;
  left: 5px;
  width: 70px;
  height: 70px;
`

export const SkeletonName = styled(SkeletonElement)`
  top: 5px;
  left: 83px;
  width: 45%;
  height: 12px;
`

export const SkeletonAuthor = styled(SkeletonElement)`
  top: 25px;
  left: 83px;
  width: 27%;
  height: 12px;
`

export const SkeletonPreview = styled(SkeletonElement)`
  bottom: 17px;
  left: 83px;
  width: 29%;
  height: 4px;
`

export const SkeletonSpotify = styled(SkeletonElement)`
  top: 5px;
  right: 5px;
  height: 16px;
  width: 16px;
`

export const SkeletonPlay = styled(SkeletonElement)`
  bottom: 5px;
  right: 5px;
  height: 32px;
  width: 32px;
  border-radius: 16px;
`
