import React, { FC } from 'react'

import { TabButtonCount, TabButtonTitle } from './styles'

interface TabCountProps {
  title: string
  count: number
}
export const TabCount: FC<TabCountProps> = ({ title, count }) => (
  <>
    <TabButtonTitle>{title}</TabButtonTitle>
    <TabButtonCount>{count}</TabButtonCount>
  </>
)
