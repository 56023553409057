import { useState, useEffect } from 'react'

import { useDebouncedCallback } from 'use-debounce'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { changeCampaign } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/api-actions'
import {
  getCampaignToSchedule,
  getIsUpdateCampaignLoading,
} from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { CampaignPlatformTypeEnum, ProperGenreOrLanguage } from 'src/Types'
import { ANY_LANGUAGE_ID, DEBOUNCE_TIMER_1500 } from 'src/Constants/constants'
import { getCurrency } from 'src/Redux/auth-process/currencySlice/selectors'
import { currencySymbolConverter } from 'src/Constants/functions'
import { DIVIDE_BY_TWO } from 'src/Constants/numeric'
import { useScheduleEvents } from 'src/Hooks/useScheduleEvents'
import { useGetBudgetRangeQuery } from 'src/Redux/spotifyBudgetRangeApi'

export const getIsLanguageActive = (
  languages: ProperGenreOrLanguage[],
): boolean => {
  const isEmpty = !languages.length
  const IsOnlyAnyLanguage =
    languages.some((language) => language.id === ANY_LANGUAGE_ID) &&
    languages.length === 1
  return Boolean(isEmpty || IsOnlyAnyLanguage)
}

interface UseCampaignPrice {
  savePrice: (value: number) => void
  rate: number
  currencySymbol: string
  min: number
  max: number
  valueInput: number
  valueSlider: number
  loading: boolean
  setValueInput: (value: number) => void
  setValueSlider: (value: number) => void
}

// This hook is used to get the price of the campaign
// and can be used only for budget slider
export const useCampaignPrice = (): UseCampaignPrice => {
  const dispatch = useAppDispatch()
  const { handleBudgetEvents } = useScheduleEvents()
  const params = useParams()
  const campaignId = params.number
  const currentCurrency = useAppSelector(getCurrency)
  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const { data: budgetRange } = useGetBudgetRangeQuery(campaignId, {
    skip: !campaignId,
  })
  const loading = useAppSelector(getIsUpdateCampaignLoading)
  const max = Number(budgetRange?.max)
  const min = Number(budgetRange?.min)
  const currencySymbol = currencySymbolConverter(currentCurrency.name)

  const [valueInput, setValueInput] = useState<number>(0)
  const [valueSlider, setValueSlider] = useState<number>(0)

  const validateValue = (value: number): number => {
    if (value > max) {
      return max
    }
    if (value < min) {
      return min
    }
    return value
  }

  const savePrice = useDebouncedCallback((value: number) => {
    void dispatch(
      changeCampaign({
        campaign_id: campaignDetails.id,
        genres_ids: campaignDetails?.genres.map((el) => Number(el.id)),
        languages_ids: campaignDetails?.languages.map((el) => Number(el.id)),
        moods_ids: null,
        max_price: validateValue(value),
      }),
    )
      .unwrap()
      .then((res) => {
        handleBudgetEvents({
          platformName: CampaignPlatformTypeEnum.SPOTIFY,
          resultPrice: res.price,
        })

        setValueInput(Math.round(res.price * currentCurrency.rate))
        setValueSlider(res.price)
        return res
      })
  }, DEBOUNCE_TIMER_1500)
  useEffect(() => {
    if (campaignDetails?.price) {
      setValueInput(Math.floor(campaignDetails.price * currentCurrency.rate))
      setValueSlider(campaignDetails.price)
      return
    }
    if (min && max && !campaignDetails?.price) {
      savePrice((min + max) / DIVIDE_BY_TWO)
    }
  }, [campaignDetails?.price, currentCurrency?.rate, max, min, savePrice])

  return {
    max,
    min,
    savePrice,
    valueInput,
    setValueInput,
    setValueSlider,
    valueSlider,
    currencySymbol,
    loading,
    rate: currentCurrency.rate,
  }
}
