import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const Content = styled.div`
  padding: 24px 0;
  width: 100%;
`

export const AlertText = styled.div`
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
`

export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
  line-height: 21px;
  margin-bottom: 4px;
`

export const TextRow = styled.div`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
  color: ${colors.mainText};
`

export const FormContainer = styled.form`
  width: 100%;
`

export const SelectLabel = styled.h3`
  margin: 40px 0 16px;
  font-size: ${variables.fontSize18};
  line-height: 27px;
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 24px 0 16px;

  @media ${device.mobileMax} {
    flex-direction: column;
  }
`

export const ButtonGrey = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  padding: 9.5px 40px;
  height: 40px;
  font-size: ${variables.fontSize14};
  border-radius: 27px;
  color: ${colors.lightGreyButton};
  background-color: ${colors.white};
  border: 1px solid ${colors.mainText};

  @media ${device.mobileMax} {
    margin-right: 0px;
    margin-bottom: 16px;
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9.5px 40px;
  height: 40px;
  font-size: ${variables.fontSize14};
  border-radius: 27px;
  color: ${colors.white};
  background-color: ${({ disabled }) =>
    disabled ? colors.greyTextBlackBg : colors.green};
  border: 1px solid
    ${({ disabled }) => (disabled ? colors.greyTextBlackBg : colors.green)};
`
