import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import AlertComponent from 'src/Components/Alert'

interface TrackToReviewAlertProps {
  isError: boolean
}

export const TrackToReviewAlert: FC<TrackToReviewAlertProps> = ({
  isError,
}) => {
  const { t } = useTranslation()
  if (!isError) {
    return null
  }
  return (
    <AlertComponent color='danger' icon unclosable>
      {t('reviewsPage.reviewError')}
    </AlertComponent>
  )
}
