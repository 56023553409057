import { FC } from 'react'

import styled from 'styled-components'

import { currencySymbolConverter } from 'src/Constants/functions'
import { useAppSelector } from 'src/Hooks/redux'
import { getCurrency } from 'src/Redux/auth-process/currencySlice/selectors'
import { colors, variables } from 'src/Styled/variables'

interface PlanPriceProps {
  price: number
}

const LocalPrice = styled.div`
  color: ${colors.greyTextWhiteBg};
  text-align: center;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
const PriceWrapper = styled.div`
  margin-bottom: 32px;
`

const Currency = styled.span`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
const Price = styled.span`
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const Period = styled.span`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
`
export const PlanPrice: FC<PlanPriceProps> = ({ price }) => {
  const { name: currencyName, rate } = useAppSelector(getCurrency)
  const currencySymbol = currencySymbolConverter(currencyName)

  const value = `~ ${currencySymbol} ${(price * rate).toFixed()}`
  return (
    <PriceWrapper>
      <Currency>$ </Currency>
      <Price>{price} </Price>
      <Period>/mo</Period>
      <LocalPrice>{value}</LocalPrice>
    </PriceWrapper>
  )
}
