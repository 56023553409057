import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

const BORDER_WIDTH = 2

export const ProgressContainer = styled.div`
  width: 170px;

  @media (max-width: 576px) {
    margin-bottom: 16px;
  }
`

export const ProgressInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 176px;
`

export const ProgressInfoItem = styled.span`
  font-size: ${variables.fontSize10};
  color: ${colors.mainText};
`

export const ProgressBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 125px;
`

export const ProgressSection = styled.div<{
  first?: boolean
  last?: boolean
  passed?: boolean
  yellow?: boolean
  red?: boolean
}>`
  width: 8px;
  height: 4px;

  border-top-left-radius: ${({ first }) => (first ? BORDER_WIDTH : 0)}px;
  border-bottom-left-radius: ${({ first }) => (first ? BORDER_WIDTH : 0)}px;
  border-top-right-radius: ${({ last }) => (last ? BORDER_WIDTH : 0)}px;
  border-bottom-right-radius: ${({ last }) => (last ? BORDER_WIDTH : 0)}px;

  background-color: ${({ passed, yellow, red }) => {
    if (passed) {
      return colors.greyStroke
    }
    if (red) {
      return colors.messegesRed
    }
    if (yellow) {
      return colors.messegesYellow1
    }
    return colors.green
  }};
`

export const StartDay = styled.span`
  margin-bottom: 8px;
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
`
