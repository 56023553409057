import { FC, useCallback, useState } from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading'

import { PlatformHeader } from 'src/Components/PlatformModal/components/PlatformHeader'
import { colors } from 'src/Styled/variables'
import { GA_LENGTH, NAVIGATE_BACK_INDEX } from 'src/Constants/constants'
import { useAppDispatch } from 'src/Hooks/redux'
import { useFirstChosenTrack } from 'src/Hooks/useFirstChosenTrack'
import {
  getCookieByName,
  getUtmParameters,
  handleDefinePlatform,
} from 'src/Constants/functions'
import {
  artistRegistrationCompleteEvent,
  fistCampaignArtistDetailsEvent,
  pageViewEvent,
  phoneNumberAddedEvent,
} from 'src/Helpers/TagManager'
import { signupLoginError } from 'src/Helpers/TagManager/AuthEvents'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { createCampaignWithoutSignup } from 'src/Redux/auth-process/credentialsSlice/api-actions'
import { createdFirst } from 'src/Router/routes'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { HelmetTitle } from 'src/Components/CreateCampaignLayout/components/HelmetTitle'

import { UserRegisterModal } from '../CreateCampaignWithoutSignup/components/UserRegisterModal'
import { FormDataProps } from '../CreateCampaignWithoutSignup/components/UserRegisterForm/UserRegisterFormProps'
import {
  tiktokEventsHandler,
  spotifyEventsHandler,
} from '../CreateCampaignWithoutSignup/components/UserRegisterModal/helper'

import { getTracksData } from './helpers'
import { useViewSignup } from './useViewSignup'

const StyledPlatformModal = styled.div`
  overflow-y: auto;
  background-color: ${colors.greyBlue};
  width: 100%;
  height: 100vh;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
const Container = styled.div`
  width: 100%;
  @media (min-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const SignupWithFirstTrack: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleBackClick = (): void => {
    navigate(NAVIGATE_BACK_INDEX)
  }
  useViewSignup()

  const { track, platform, loading } = useFirstChosenTrack()
  const { search } = useCustomSearchParams()
  const referralCode = search?.get('referralCode')

  const [emailExist, setEmailExist] = useState(false)

  const handleResetEmailExist = (): void => {
    setEmailExist(false)
  }

  const setGTMEventWhenEnterPhoneNumber = (): void => {
    phoneNumberAddedEvent()
  }

  const handleSubmit = useCallback(
    (data: FormDataProps, token: string): void => {
      const gcid = getCookieByName('_ga')?.slice(GA_LENGTH) || ''
      const utmObj = getUtmParameters(getCookieByName('soundCampaignUtm'))

      fistCampaignArtistDetailsEvent()

      void dispatch(
        createCampaignWithoutSignup({
          gcid,
          utmObj,
          artist: data,
          captchaToken: token,
          referral_code: utmObj.ref || '',
          internal_referral_code: referralCode || '',
          ...getTracksData(track),
        }),
      )
        .unwrap()
        .then((res) => {
          res.tiktok_campaign
            ? tiktokEventsHandler({ res, dispatch })
            : spotifyEventsHandler({ res })

          pageViewEvent({ currentPageInfo: pageInfo.CreatedFirstCampaign })

          artistRegistrationCompleteEvent({
            platformName: handleDefinePlatform({
              isSpotifyTrack: platform === CampaignPlatformTypeEnum.SPOTIFY,
              isTikTokTrack: platform === CampaignPlatformTypeEnum.TIKTOK,
            }),
            userId: res.user.id,
            userRole: res.user.role,
            spotifyCampaignId: res.spotify_campaign?.id,
            tiktokCampaignId: res.tiktok_campaign?.id,
            isFeed: Boolean(res.spotify_campaign?.is_feed) || false,
          })
          const campaignType = res.tiktok_campaign ? 'tiktok' : 'spotify'
          navigate(`${createdFirst}/${campaignType}`)
          return res
        })
        .catch(() => {
          signupLoginError()
          setEmailExist(true)
        })
    },
    [platform, dispatch, referralCode, track, navigate],
  )

  if (loading) {
    return (
      <LoaderContainer>
        <ReactLoading height={44} color={colors.green} type='bars' />
      </LoaderContainer>
    )
  }
  return (
    <StyledPlatformModal>
      <PlatformHeader onBackClick={handleBackClick} />
      <HelmetTitle />
      <Container>
        <UserRegisterModal
          track={track}
          platform={platform}
          onSubmit={handleSubmit}
          emailExist={emailExist}
          setGTMEventWhenEnterPhoneNumber={setGTMEventWhenEnterPhoneNumber}
          onResetEmailExist={handleResetEmailExist}
          onBackClick={handleBackClick}
        />
      </Container>
    </StyledPlatformModal>
  )
}
