import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { RejectReason } from 'src/Types'

const rejectReasonApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getRejectReasons: builder.query<RejectReason[], void>({
      query: () => `${APP.SERVER}/review/reject-reasons`,
    }),
  }),
  overrideExisting: false,
})

export const { useGetRejectReasonsQuery } = rejectReasonApi
