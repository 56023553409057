import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'
import { UserNotification } from 'src/Types'
import { toReversed } from 'src/Constants/functions'

import { ApiError, NameSpace } from '../types'

import {
  getArtistNotifications,
  getCuratorNotifications,
  markArtistNotificationsAsRead,
  markCuratorNotificationsAsRead,
} from './api-actions'

export interface NotificationState {
  notifications: Array<UserNotification>
  loading: boolean
  error: ApiError | null
}

const initialState: NotificationState = {
  notifications: [],
  loading: false,
  error: null,
}

export const notificationProcess = createSlice({
  initialState,
  name: NameSpace.Notifications,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getArtistNotifications.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getArtistNotifications.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(getArtistNotifications.fulfilled, (state, { payload }) => {
      state.notifications = toReversed(payload)
      state.error = null
      state.loading = false
    })

    builder.addCase(getCuratorNotifications.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCuratorNotifications.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(getCuratorNotifications.fulfilled, (state, { payload }) => {
      state.notifications = toReversed(payload)
      state.error = null
      state.loading = false
    })

    builder.addCase(markCuratorNotificationsAsRead.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      markCuratorNotificationsAsRead.rejected,
      (state, action) => {
        state.loading = false
        state.error = setApiError(action)
      },
    )
    builder.addCase(markCuratorNotificationsAsRead.fulfilled, (state) => {
      state.notifications = state.notifications.map((el) => ({
        ...el,
        isNew: false,
      }))
      state.error = null
      state.loading = false
    })

    builder.addCase(markArtistNotificationsAsRead.pending, (state) => {
      state.loading = true
    })
    builder.addCase(markArtistNotificationsAsRead.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(markArtistNotificationsAsRead.fulfilled, (state) => {
      state.notifications = state.notifications.map((el) => ({
        ...el,
        isNew: false,
      }))
      state.error = null
      state.loading = false
    })
  },
})
