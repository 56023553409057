import { FC } from 'react'

import styled from 'styled-components'

import { VideoStatus } from 'src/Constants/enums'
import { CampaignPreModerationVideo } from 'src/Redux/campaignVideos-process/selectors'
import { TikTokCampaignPricingModelEnum } from 'src/Types'

import { StatusBadgeOld } from '../StatusBadgeOld'
import { StatusBadge } from '../StatusBadge'

interface VideoStatusBlockProps {
  video?: CampaignPreModerationVideo | null
}

export const Container = styled.div`
  width: fit-content;
`

export const VideoStatusBlock: FC<VideoStatusBlockProps> = ({ video }) => {
  const isVideosBasedModal =
    (!video?.minViewsDeadline && !video?.preModerationDate) ||
    video?.pricingModelVersion === TikTokCampaignPricingModelEnum.FollowersBased

  return (
    <Container>
      {isVideosBasedModal ? (
        <StatusBadgeOld
          videoStatus={video?.status ?? VideoStatus.APPROVED_AUTOMATICALLY}
        />
      ) : (
        <StatusBadge preModerationStatus={video?.preModerationStatus} />
      )}
    </Container>
  )
}
