import React, { FunctionComponent } from 'react'

import {
  Skeleton,
  SkeletonAvatar,
  SkeletonName,
  SkeletonAuthor,
  SkeletonPreview,
  SkeletonSpotify,
  SkeletonPlay,
} from './styles'

export const IFrameSkeleton: FunctionComponent = () => (
  <Skeleton>
    <SkeletonAvatar />
    <SkeletonName />
    <SkeletonAuthor />
    <SkeletonPreview />
    <SkeletonSpotify />
    <SkeletonPlay />
  </Skeleton>
)
