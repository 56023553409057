import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'

interface GetCurrencyInfoResponse {
  currency_rate: number
  currency_name: string
}

export const getCurrencyInfo = createAsyncThunk<GetCurrencyInfoResponse>(
  `${NameSpace.Currency}/getCurrencyInfo`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<GetCurrencyInfoResponse>(
        `${APP.SERVER}/user/exchange-rate`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)
