import { ReactElement, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { campaignsList, createCampaign } from 'src/Router/routes'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import {
  getIsCreateTikTokCampaignAvailableSelector,
  getIsDiscoverTikTokCampaignAvailableSelector,
  useMetadataQuery,
} from 'src/Redux/metadataApi'
import { useBonusTooltipTimer } from 'src/Hooks/useBonusTooltip'
import dayJs from 'src/Helpers/dayjs'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import {
  discoverTiktokPromotionEvent,
  thankPageCreateTiktokCampaignEvent,
} from 'src/Helpers/TagManager'
import { tikTokPromotionLink } from 'src/Constants/links'
import { useHint } from 'src/Hooks/useHint'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { removeCoupon } from 'src/Redux/schedule-process/couponSlice'

import { GreenText } from './styles'

export const useSuccessPage = (
  startDate?: string | Date,
): {
  isLoading: boolean
  isSuccessPageVisible: boolean
  isUpsellButtonVisible: boolean
  onUpsellButtonClick: () => void
  upsellButtonText: string
  isGoToBalanceButtonVisible: boolean
  successMessage: ReactElement
} => {
  const { setIsOpen } = useHint()
  const dispatch = useAppDispatch()

  const { t } = useTranslation()
  const { search } = useCustomSearchParams()

  const urlStartDate = search.get('startDate')

  // eslint-disable-next-line no-undefined
  const { isLoading, data, refetch } = useMetadataQuery(undefined, {
    refetchOnMountOrArgChange: 1,
  })

  const { isNextTimeBonusReceived } = useBonusTooltipTimer()
  const spotifyCampaignDetails = useAppSelector(getCampaignToSchedule)
  const userId = useAppSelector(getUserId)

  const isCreateTikTokCampaignButtonVisible =
    getIsCreateTikTokCampaignAvailableSelector(data)
  const isDiscoverTikTokCampaignButtonVisible =
    getIsDiscoverTikTokCampaignAvailableSelector(data)

  const createTikTokCampaignLink = `${campaignsList}${createCampaign}/${
    CampaignPlatformTypeEnum.TIKTOK
  }?trackName=${encodeURIComponent(spotifyCampaignDetails?.track?.title || '')}`

  const isUpsellButtonVisible =
    isCreateTikTokCampaignButtonVisible || isDiscoverTikTokCampaignButtonVisible

  const isGoToBalanceButtonVisible =
    isNextTimeBonusReceived && !isDiscoverTikTokCampaignButtonVisible

  const upsellButtonText = isCreateTikTokCampaignButtonVisible
    ? t('scheduleCampaignPage.createTikTok')
    : t('scheduleCampaignPage.discoverTikTok')

  const currentStartDate = urlStartDate || startDate
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const onlyDate = dayJs(currentStartDate).tz(timezone).format('MMMM Do')

  const successMessage = (
    <>
      {t('scheduleCampaignPage.awesomeYourCampaignIsScheduledAndWillStartOn')}
      <GreenText>{` ${onlyDate}`}</GreenText>
    </>
  )

  const onUpsellButtonClick = (): void => {
    if (isCreateTikTokCampaignButtonVisible) {
      window.open(createTikTokCampaignLink, '_blank')

      thankPageCreateTiktokCampaignEvent({ userId })
    } else {
      discoverTiktokPromotionEvent({
        userId,
      })
      window.open(tikTokPromotionLink, '_blank')
    }
    setIsOpen()
  }

  const paymentStatus = search.get('paymentStatus')

  const isSuccessPageVisible = paymentStatus === 'success'

  useEffect(() => {
    isSuccessPageVisible && dispatch(removeCoupon())
    refetch()
  }, [dispatch, isSuccessPageVisible, refetch])

  return {
    isLoading,
    isUpsellButtonVisible,
    successMessage,
    upsellButtonText,
    onUpsellButtonClick,
    isSuccessPageVisible,
    isGoToBalanceButtonVisible,
  }
}
