import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Chat from 'src/Assets/Svg/sidebar-icons/Chat.svg?react'
import Feedback from 'src/Assets/Svg/sidebar-icons/Feedback.svg?react'
import Plus from 'src/Assets/Svg/sidebar-icons/Plus.svg?react'

import { SidebarMenuItem } from '../SidebarMenuItem'
import { SidebarProps } from '../..'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

type SidebarButtonsProps = Pick<
  SidebarProps,
  | 'collapsed'
  | 'showFeatureRequest'
  | 'showFeedback'
  | 'onFeedbackClick'
  | 'onChatClick'
  | 'onFeatureClick'
>

export const SidebarButtons: FC<SidebarButtonsProps> = (props) => {
  const {
    collapsed,
    showFeatureRequest,
    showFeedback,
    onFeedbackClick,
    onChatClick,
    onFeatureClick,
  } = props
  const { t } = useTranslation()
  return (
    <Container>
      {showFeatureRequest && (
        <SidebarMenuItem
          onClick={onFeatureClick}
          icon={Plus}
          collapsed={collapsed}
          type='button'
        >
          Request feature
        </SidebarMenuItem>
      )}
      <SidebarMenuItem
        onClick={onChatClick}
        icon={Chat}
        collapsed={collapsed}
        type='button'
      >
        {t('sidebar.liveChat')}
      </SidebarMenuItem>
      {showFeedback && (
        <SidebarMenuItem
          icon={Feedback}
          collapsed={collapsed}
          onClick={onFeedbackClick}
          type='button'
        >
          {t('sidebar.shareFeedback')}
        </SidebarMenuItem>
      )}
    </Container>
  )
}
