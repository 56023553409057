import styled from 'styled-components'

import { matchingGradientLimits } from 'src/Constants/numeric'
import { variables, colors, device } from 'src/Styled/variables'

const calculateRateColor = ({
  rate,
  isGenres,
}: {
  rate: number
  isGenres: boolean
}): string => {
  if (!isGenres) {
    return colors.green
  }
  if (
    rate >= matchingGradientLimits.red[0] &&
    rate <= matchingGradientLimits.red[1]
  ) {
    return colors.messegesRed
  }
  if (
    rate >= matchingGradientLimits.orange[0] &&
    rate <= matchingGradientLimits.orange[1]
  ) {
    return colors.messegesYellow1
  }
  return colors.green
}

export const Container = styled.div`
  margin-top: 16px;
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.mobileMax} {
    margin-bottom: 24px;
  }
  @media ${device.largeScreen} {
    margin-top: 0px;
    max-width: 340px;
  }
`

export const Title = styled.span`
  margin-bottom: 4px;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 15px;
  color: ${colors.mainText};
`

export const MatchedRate = styled(Title)<{
  isGenres: boolean
  matchedRate: number
}>`
  color: ${({ matchedRate, isGenres }) =>
    calculateRateColor({ rate: matchedRate, isGenres })};
`

export const MatchedGenres = styled.span`
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
  color: ${colors.mainText};
`
