import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const AccountReactivationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 140px;

  @media ${device.mobileMin} {
    align-items: center;
    height: 100vh;
    justify-content: center;
    margin-top: -107px;
  }

  @media ${device.mobileMax} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: ${colors.white};
  }
`
export const Block = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 552px;
  text-align: center;
  @media ${device.mobileMax} {
    width: 288px;
  }
`

export const Title = styled.h4`
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize18};
  margin-bottom: 16px;
  color: ${colors.mainText};
  line-height: 150%;
  & img {
    margin-left: 5px;
    width: 16px;
    height: 16px;
  }
`

export const Text = styled.span`
  line-height: 150%;
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  padding: 0;
  @media ${device.mobileMin} {
    padding: 0 92px;
  }
`

export const ButtonsContainer = styled.div`
  margin-top: 56px;
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
  div {
    width: 34%;
    @media ${device.mobileMax} {
      width: 64%;
    }
  }
`

export const Circle = styled.div`
  margin: 0 auto 56px auto;
  margin-bottom: 56px;
  display: inline-block;
  width: 48px;
  height: 48px;
`
