import styled from 'styled-components'
import { Divider } from 'antd'

import { colors } from 'src/Styled/variables'
import CrossIcon from 'src/Assets/Svg/close-cross.svg?react'

export const Separator = styled(Divider)`
  margin: 16px 0;
`

export const CrossIconStyled = styled(CrossIcon)`
  width: 12px;
  height: 12px;
  path {
    fill: ${colors.green};
  }
`

export const GenreListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  gap: 8px;
`
export const GenreItemStyled = styled.li`
  display: flex;
  padding: 9.5px 12px;
  align-items: center;
  width: fit-content;
  gap: 8px;
  border-radius: 4px;
  background-color: ${colors.green1Light};
  color: ${colors.green};
  cursor: pointer;
`
