import { FC } from 'react'

import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Paginator = styled.div<{ active?: boolean }>`
  margin-right: 4px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ active }) =>
    active ? colors.green : colors.green1Light};
`
const ADD_TWO = 2
const list = Array.from({ length: 5 }).map((_, index) => index + ADD_TWO)

interface PaginationProps {
  activeItem: number
}

export const Pagination: FC<PaginationProps> = ({ activeItem }) => (
  <PaginationContainer>
    {list.map((item) => (
      <Paginator
        data-testid='paginator'
        key={item}
        active={item === activeItem}
      />
    ))}
  </PaginationContainer>
)
