import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const SectionTitle = styled.h5`
  margin: 40px 0 16px 0;
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight400};
  line-height: 27px;
  color: ${colors.mainText};
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
`
export const HiddenContentWrapper = styled.div<{ active?: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};
`
export const Warning = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const WarningText = styled.span`
  width: 90%;
  font-size: ${variables.fontSize14};
  color: ${colors.messegesYellow1};
`

export const ButtonsContainer = styled.div`
  padding: 30px 0 24px;
  display: flex;
  gap: 16px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  width: 240px;

  @media ${device.mobileMax} {
    flex-direction: column-reverse;
    width: 160px;
  }
`
