import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Button from 'src/Components/Buttons/Button'
import SpotifyIcon from 'src/Assets/Svg/spotify.svg?react'
import TiktokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { device } from 'src/Styled/variables'
import { campaignsList, createCampaign } from 'src/Router/routes'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { createCampaignStartEvent } from 'src/Helpers/TagManager'
import { getAmountOfCampaigns } from 'src/Redux/campaignList-process'

const StyledButton = styled(Button)`
  @media screen and (min-width: 1000px) {
    button {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  @media screen and (max-width: 390px) {
    button {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  @media ${device.mobileMax} {
    gap: 8px;
  }
`

export const CreateCampaignButtons: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useAppSelector(getUserSelector)
  const amountOfCampaigns = useAppSelector(getAmountOfCampaigns)

  const campaignNumber = amountOfCampaigns + 1

  const handleClick = (to: string): void => {
    navigate(to)
    createCampaignStartEvent(user, campaignNumber)
  }
  return (
    <Wrapper>
      <StyledButton
        onClick={() =>
          handleClick(
            `${campaignsList}${createCampaign}/${CampaignPlatformTypeEnum.SPOTIFY}`,
          )
        }
        type='whiteWithGreenBorder'
      >
        <SpotifyIcon width='16px' height='16px' />
        {t('createCampaignPage.createCampaign')}
      </StyledButton>
      <StyledButton
        onClick={() =>
          handleClick(
            `${campaignsList}${createCampaign}/${CampaignPlatformTypeEnum.TIKTOK}`,
          )
        }
        type='whiteWithGreenBorder'
      >
        <TiktokIcon width='16px' height='16px' />
        {t('createCampaignPage.createCampaign')}
      </StyledButton>
    </Wrapper>
  )
}
