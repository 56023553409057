import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Button from 'src/Components/Buttons/Button'
import { colors, variables } from 'src/Styled/variables'
export const ReservedLabel = styled.p`
  margin: 56px 0 32px;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  text-align: center;
  color: ${colors.messegesYellow1} !important;
`
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  > div {
    width: fit-content;
    .ant-btn.ant-btn-default {
      padding: 0 35px;
    }
  }
`

interface StopTimerMessageProps {
  onClick: () => void
}

export const StopTimerMessage: FC<StopTimerMessageProps> = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <div>
      <ReservedLabel>
        Oops, you`re a bit late. The last review for this track has already been
        submitted
      </ReservedLabel>
      <ButtonContainer>
        <Button type='white' onClick={onClick}>
          {t('reviewsPage.skipThisReview')}
        </Button>
      </ButtonContainer>
    </div>
  )
}
