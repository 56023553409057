import { FC } from 'react'

import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

interface ReviewedTrackCommentProps {
  comment?: string
  title: string
}

const StyledReviewedTrackComment = styled.div`
  overflow-wrap: break-word;
`
const TrackDetailsTitle = styled.div`
  font-size: ${variables.fontSize10};
  color: ${colors.mainText};
`
const TrackDetailsRowInfo = styled.div<{ color?: string }>`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
`
export const ReviewedTrackComment: FC<ReviewedTrackCommentProps> = ({
  comment,
  title,
}) => (
  <StyledReviewedTrackComment>
    <TrackDetailsTitle>{title}</TrackDetailsTitle>
    <TrackDetailsRowInfo>{comment}</TrackDetailsRowInfo>
  </StyledReviewedTrackComment>
)
