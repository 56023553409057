import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Image3 from 'src/Assets/Svg/image3.svg?react'

import {
  StyledButton,
  ButtonBack,
  ButtonsContainer,
  ImageWrapper,
  List,
  ListItem,
  Text,
  TextContainer,
  Title,
} from '../styles'
import { Slides } from '../useSlidesContent'
import { Pagination } from '../Pagination/Pagination'

interface ThirdStepProps {
  onNextClick: () => void
  onBackClick: () => void
  disabled?: boolean
}

export const ThirdStep: FC<ThirdStepProps> = ({
  onNextClick,
  onBackClick,
  disabled,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <ImageWrapper>
        <Image3 data-testid={'third image'} />
      </ImageWrapper>
      <Title>{t('termsAndConditions.title3')}</Title>
      <TextContainer>
        <Text>{t('termsAndConditions.text3-1')}</Text>
        <List marginTop>
          <ListItem>{t('termsAndConditions.list3-1')}</ListItem>
          <ListItem>{t('termsAndConditions.list3-2')}</ListItem>
          <ListItem>{t('termsAndConditions.list3-3')}</ListItem>
          <ListItem>{t('termsAndConditions.list3-4')}</ListItem>
          <ListItem>{t('termsAndConditions.list3-5')}</ListItem>
          <ListItem>{t('termsAndConditions.list3-6')}</ListItem>
          <ListItem>{t('termsAndConditions.list3-7')}</ListItem>
        </List>
      </TextContainer>
      <Pagination activeItem={Slides.THIRD} />
      <ButtonsContainer>
        <ButtonBack disabled={disabled} onClick={onBackClick}>
          {t('termsAndConditions.back')}
        </ButtonBack>
        <StyledButton disabled={disabled} onClick={onNextClick}>
          {t('termsAndConditions.continue')}
        </StyledButton>
      </ButtonsContainer>
    </>
  )
}
