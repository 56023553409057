import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'
export const AlertText = styled.div`
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
`

export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
`

export const AlertButton = styled.button`
  background-color: transparent;
  color: ${colors.messegesRed};
  font-weight: ${variables.fontWeight600};
  padding-left: 0;
  cursor: pointer;
  &:hover {
    color: ${colors.messegesRed};
  }
`
