import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'
import { getSpotifyTrackId } from 'src/Constants/functions'

import { SpotifyIFrame } from '../SpotifyIframe'

const TrackPreview = styled.div`
  height: 88px;
  padding: 4px;
  box-shadow: 0px 0px 6px 0px #00000040;
  border-radius: 14px;
`

const OpenSpotify = styled.span`
  display: block;
  margin: 17px 0 9px;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
  text-align: center;
  position: relative;
  z-index: 1001;
`
const OpenSpotifyLink = styled.a`
  margin-left: 4px;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  color: ${colors.green};
  border-bottom: 1px dashed ${colors.green};
  cursor: pointer;
  &:hover,
  &:visited {
    color: ${colors.green};
  }
`

interface SpotifyTrackPreviewProps {
  trackUrl?: string
}
export const SpotifyTrackPreview: FC<SpotifyTrackPreviewProps> = ({
  trackUrl,
}) => {
  const { t } = useTranslation()
  const trackId = getSpotifyTrackId(trackUrl)

  const link = `https://open.spotify.com/track/${trackId}`

  const handleClickSpotifyLink = (): void => {
    window.open(link, '_blank')
  }

  return (
    <div>
      <TrackPreview>
        {Boolean(trackId) && <SpotifyIFrame trackId={trackId} />}
      </TrackPreview>
      <OpenSpotify>
        {t('reviewsPage.orOpenOn')}
        <OpenSpotifyLink
          href={link}
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleClickSpotifyLink}
          onTouchStart={handleClickSpotifyLink}
        >
          Spotify
        </OpenSpotifyLink>
      </OpenSpotify>
    </div>
  )
}
