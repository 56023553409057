import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Image7 from 'src/Assets/Svg/image7.svg?react'
import confetti from 'src/Assets/Gif/confetti.gif'

import {
  StyledButton,
  ButtonsContainer,
  Confetti,
  ImageWrapper,
  SubTitle,
  SubTitleContainer,
} from '../styles'

interface FinalStepProps {
  onFinalClick: () => void
  disabled?: boolean
}

export const FinalStep: FC<FinalStepProps> = ({ onFinalClick, disabled }) => {
  const { t } = useTranslation()
  return (
    <>
      <Confetti src={confetti} alt='confetti' />
      <ImageWrapper>
        <Image7 />
      </ImageWrapper>
      <SubTitleContainer>
        <SubTitle>{t('termsAndConditions.subTitle7')}</SubTitle>
      </SubTitleContainer>
      <ButtonsContainer>
        <StyledButton disabled={disabled} onClick={onFinalClick}>
          {t('termsAndConditions.continue')}
        </StyledButton>
      </ButtonsContainer>
    </>
  )
}
