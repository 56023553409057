export const campaignDuration = 14

const RED_END_LIMIT = 33
const ORANGE_END_LIMIT = 66
const GREEN_END_LIMIT = 100
const ORANGE_START_LIMIT = 34
const GREEN_START_LIMIT = 67

export const matchingGradientLimits = {
  red: [1, RED_END_LIMIT],
  orange: [ORANGE_START_LIMIT, ORANGE_END_LIMIT],
  green: [GREEN_START_LIMIT, GREEN_END_LIMIT],
}

export const POSITIONS_AMOUNT = 20
export const SHARING_IMAGE_LENGTH_LIMIT = 280
export const MAX_MOBILE_WIDTH = 576
export const DEBOUNCE_DELAY = 1000
export const MILLISECONDS_IN_SECONDS = 1000
export const HUNDREDTHS = 2
export const HUNDRED = 100
export const REVIEW_TEXT_MIN_LENGTH = 70
export const MAX_COMMENT_LENGTH = 700
export const DIVIDE_BY_TWO = 2
export const PLAYLIST_MAX_LENGTH_NAME = 45
export const NAVIGATE_BACK = -1
