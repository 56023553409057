import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Image1 from 'src/Assets/Svg/image1.svg?react'

import {
  StyledButton,
  ButtonsContainer,
  ImageWrapper,
  Text,
  TextContainer,
  Title,
} from '../styles'

interface FirthStepProps {
  onClick: () => void
  disabled: boolean
}

export const FirthStep: FC<FirthStepProps> = ({ onClick, disabled }) => {
  const { t } = useTranslation()
  return (
    <>
      <ImageWrapper>
        <Image1 data-testid={'first image'} />
      </ImageWrapper>
      <Title>{t('termsAndConditions.title1')}</Title>
      <TextContainer edge>
        <Text>{t('termsAndConditions.text1-1')}</Text>
        <Text>{t('termsAndConditions.text1-2')}</Text>
        <Text orange>{t('termsAndConditions.text1-3')}</Text>
      </TextContainer>
      <ButtonsContainer>
        <StyledButton disabled={disabled} onClick={onClick}>
          {t('termsAndConditions.takeALook')}
        </StyledButton>
      </ButtonsContainer>
    </>
  )
}
