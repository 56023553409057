import styled from 'styled-components'
import { Modal } from 'antd'

import { variables, colors, device } from 'src/Styled/variables'

import { Props } from '.'

export const StyledModal = styled(Modal)<Props>`
  position: fixed;
  border-radius: 0px;
  top: 56px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0 auto !important;
  width: 100% !important;
  max-width: 100% !important;
  @media ${device.tabletMax} {
    top: ${({ showTopbar }) => (showTopbar ? '60px' : '0px')};
  }
  & > div {
    height: 100%;
    position: relative;
  }

  .${({ prefixCls }) => (prefixCls ? prefixCls : 'ant-modal')}-content {
    width: 100%;
    height: 100%;
    @media ${device.mobileMax} {
      background-color: ${({ showTopbar }) =>
        showTopbar ? colors.greyBlue : colors.white};
    }
    border-radius: 0px;
    padding: 0;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .${({ prefixCls }) => (prefixCls ? prefixCls : 'ant-modal')}-close {
      position: fixed;
      top: 76px;
      display: ${({ prefixCls }) =>
        prefixCls === 'comment' ? 'none' : 'flex'};
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;

      .${({ prefixCls }) => (prefixCls ? prefixCls : 'ant-modal')}-close-x {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${colors.greyTextWhiteBg};

        svg {
          width: 20px;
          height: 20px;
        }
      }
      @media ${device.tabletMax} {
        display: none;
      }
    }
    .${({ prefixCls }) => (prefixCls ? prefixCls : 'ant-modal')}-header {
      padding: 65px 0 0 0;
      border-radius: 8px 8px 0 0;
      background-color: transparent;
      border: none;

      .${({ prefixCls }) => (prefixCls ? prefixCls : 'ant-modal')}-title {
        font-size: ${variables.fontSize18};
        font-weight: ${variables.fontWeight400};
        color: ${colors.mainText};
      }
    }
    .${({ prefixCls }) => (prefixCls ? prefixCls : 'ant-modal')}-body {
      position: relative;
      padding: '0 24px';
      border-radius: 0 0 8px 8px;
    }
    .${({ prefixCls }) => (prefixCls ? prefixCls : 'ant-modal')}-footer {
      display: none;
    }
  }
`
