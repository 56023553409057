import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DropdownMenu } from 'src/Components/DropdownMenu'
import FilterIcon from 'src/Assets/Svg/filter.svg?react'
import { colors, device, variables } from 'src/Styled/variables'
import CheckboxAntd from 'src/Containers/ui/CheckboxAntd'
import Button from 'src/Components/Buttons/Button'
import { CampaignsFilters } from 'src/Types'

import { CampaignsListTableActionProps } from '..'

export const FilterButton = styled.button<{ isFocused?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: ${colors.greyBg};
  color: ${colors.greyTextWhiteBg};
  border: 1px solid ${colors.greyStroke};
  border-radius: 20px;
  gap: 9px;
  cursor: pointer;
  transition: 0.15s all linear;
  ${({ isFocused }) =>
    isFocused ? 'box-shadow: inset 0px 2px 8px rgba(0, 0, 0, 0.15);' : ''}
  width: 40px;
  & svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
  }
`

export const FiltersMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
`

export const FiltersLabel = styled.h5`
  font-size: ${variables.fontSize14};
  line-height: 1.5;
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
  margin-bottom: 16px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobileMax} {
    flex-direction: row;
  }
`

export const ApplyButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0 8px 0;
  div {
    width: 70%;
    @media ${device.mobileMax} {
      width: 50%;
    }
  }
`

export const Checkbox = styled.div`
  flex: 1;
`

export const inlineStyles = {
  dropdownMenu: {
    marginRight: 16,
  },
}

type Props = Pick<CampaignsListTableActionProps, 'filters' | 'setFilters'>

export const PlatformFilter: FC<Props> = ({ filters, setFilters }) => {
  const { t } = useTranslation()
  const [filtersState, setFiltersState] = useState<CampaignsFilters>(filters)
  return (
    <DropdownMenu
      isRightOriented
      menuStyle={inlineStyles.dropdownMenu}
      renderContent={(toggleMenu) => (
        <FiltersMenu>
          <FiltersLabel>{t('createCampaignPage.platform')}</FiltersLabel>
          <Container>
            <Checkbox>
              <CheckboxAntd
                title='Spotify'
                checked={filtersState.isSpotify}
                onChange={(e) => {
                  setFiltersState((prev) => ({
                    ...prev,
                    isSpotify: e.target.checked,
                  }))
                }}
              />
            </Checkbox>
            <Checkbox>
              <CheckboxAntd
                title='TikTok'
                checked={filtersState.isTikTok}
                onChange={(e) => {
                  setFiltersState((prev) => ({
                    ...prev,
                    isTikTok: e.target.checked,
                  }))
                }}
              />
            </Checkbox>
          </Container>
          <ApplyButton>
            <Button
              type='green'
              disabled={
                JSON.stringify(filtersState) === JSON.stringify(filters)
              }
              onClick={() => {
                setFilters(filtersState)
                toggleMenu()
              }}
            >
              {t('createCampaignPage.apply')}
            </Button>
          </ApplyButton>
        </FiltersMenu>
      )}
      renderTrigger={(isOpen) => (
        <FilterButton isFocused={isOpen}>
          <FilterIcon width={'16px'} height={'16px'} />
        </FilterButton>
      )}
    />
  )
}
