import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'
import { Rating } from 'src/Components/Rating/Rating'
import { ArtistFeedbackComponent } from 'src/Components/ArtistFeedbackComponent'
import { ArtistFeedback } from 'src/Types'

interface ReviewedTrackArtistResponseProps {
  curatorRating?: number
  reviewFeedback?: ArtistFeedback
}

const Title = styled.span`
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight400};
  line-height: 15px;
  color: ${colors.mainText};
`

const ResponsePlaceholder = styled.span`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  color: ${colors.messegesYellow1};
  text-align: center;
`
const RespondedContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const RespondedRating = styled.div`
  display: flex;
  margin-top: 1px;
  width: 68px;
`
const RespondedFeedbackRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`

export const ReviewedTrackArtistResponse: FC<
  ReviewedTrackArtistResponseProps
> = ({ curatorRating, reviewFeedback }) => {
  const { t } = useTranslation()
  if (!curatorRating && !reviewFeedback) {
    return (
      <ResponsePlaceholder>
        {t('reviewsPage.responsePlaceholder')}
      </ResponsePlaceholder>
    )
  }
  return (
    <div>
      <Title>{t('reviewsPage.responseFromArtist')}</Title>
      <RespondedContainer>
        {curatorRating && (
          <RespondedRating>
            <Rating rating={curatorRating} />
          </RespondedRating>
        )}
        {reviewFeedback && (
          <RespondedFeedbackRow>
            <ArtistFeedbackComponent feedback={reviewFeedback} />
          </RespondedFeedbackRow>
        )}
      </RespondedContainer>
    </div>
  )
}
