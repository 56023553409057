import styled from 'styled-components'
import { Modal } from 'antd'

import { variables, colors, device } from 'src/Styled/variables'

export const ModalContainer = styled(Modal)`
  .ant-modal-content {
    width: 600px;
    border-radius: 8px;

    .ant-modal-close {
      display: none;
    }
    .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      padding: 0 24px 24px 24px;
      border-radius: 0 0 8px 8px;
    }
    .ant-modal-footer {
      display: none;
    }
  }
`

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.mobileMax} {
    padding: 16px;
    background: ${colors.white};
    border-radius: 4px;
  }
`

export const MobileContainer = styled.div`
  padding-bottom: 100px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 16px;
  background: ${colors.greyBlue};
  border-radius: 4px;
  position: absolute;
  top: 60px;
  bottom: 0px;
  z-index: 2;
  left: 0;
  height: fit-content;
`

export const ImageWrapper = styled.div`
  position: relative;
  top: -34px;
  height: auto;
  width: auto;

  @media ${device.mobileMax} {
    top: 0;
  }
`

export const Title = styled.h3`
  margin-top: 14px;
  margin-bottom: 24px;
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight400};
  line-height: 30px;
  color: ${colors.mainText};

  @media ${device.mobileMax} {
    margin-top: 40px;
  }
`

export const SubTitle = styled.h4`
  margin-bottom: 44px;
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
  text-align: center;
`

export const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;

  @media ${device.mobileMax} {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 16px;
    background: ${colors.white};
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  }
`

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 9.5px 40px;
  height: 40px;
  font-size: ${variables.fontSize14};
  border-radius: 27px;
  color: ${colors.white};
  background-color: ${({ disabled }) =>
    disabled ? colors.greyTextBlackBg : colors.green};
  border: 1px solid
    ${({ disabled }) => (disabled ? colors.greyTextBlackBg : colors.green)};
  &:disabled {
    cursor: not-allowed;
  }
`

export const ButtonBack = styled(StyledButton)`
  margin-right: 16px;
  cursor: pointer;
  color: ${colors.green};
  background-color: ${({ disabled }) =>
    disabled ? colors.greyTextBlackBg : colors.white};
  border: 1px solid
    ${({ disabled }) => (disabled ? colors.greyTextBlackBg : colors.mainText)};
  color: ${({ disabled }) => (disabled ? colors.white : colors.green)};
`

const MIN_HEIGHT_EDGE = 211

export const TextContainer = styled.div<{ edge?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 38px;
  width: 100%;
  min-height: ${({ edge }) => (edge ? 0 : MIN_HEIGHT_EDGE)}px;
`

export const SubTitleContainer = styled(TextContainer)`
  align-items: center;
  min-height: 211px;
  align-items: center;
  justify-content: flex-end;

  @media ${device.mobileMax} {
    min-height: 0;
  }
`

export const Paragraph = styled.p`
  margin: 0;
  padding-top: 16px;

  &:first-child {
    padding-top: 0;
  }
`
const PADDING_TOP_MONOSPACE = 16

export const Text = styled.span<{
  orange?: boolean
  bold?: boolean
  monospace?: boolean
}>`
  padding-top: ${({ monospace }) => (monospace ? 0 : PADDING_TOP_MONOSPACE)}px;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  font-weight: ${({ bold }) =>
    bold ? variables.fontWeight600 : variables.fontWeight400};
  color: ${({ orange }) => (orange ? colors.messegesYellow1 : colors.mainText)};

  &:first-child {
    padding-top: 0;
  }
`

export const Link = styled.a`
  margin-left: 4px;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.green};
  border-bottom: 1px dashed ${colors.green};
`

export const List = styled.ul<{ marginTop?: boolean }>`
  margin-top: ${({ marginTop }) => (marginTop ? 24 : 0)}px;
  margin-left: 8px;
`

export const ListItem = styled.li`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
`

export const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  justify-content: center;
  grid-template-rows: none;
  align-items: self-start;
  @media ${device.mobileMax} {
    margin-bottom: 16px;
  }
`

export const Confetti = styled.img`
  position: absolute;
  top: 20;
  max-height: 100%;
  max-width: 310px;
  width: 100%;
  z-index: 0;
`

export const inlineStyles = {
  modalMask: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}
