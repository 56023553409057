import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Image2 from 'src/Assets/Svg/image2.svg?react'

import {
  StyledButton,
  ButtonBack,
  ButtonsContainer,
  ImageWrapper,
  List,
  ListItem,
  Text,
  TextContainer,
  Title,
} from '../styles'
import { Pagination } from '../Pagination/Pagination'
import { Slides } from '../useSlidesContent'

interface SecondStepProps {
  onNextClick: () => void
  onBackClick: () => void
  disabled: boolean
}

export const SecondStep: FC<SecondStepProps> = ({
  onBackClick,
  disabled,
  onNextClick,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <ImageWrapper>
        <Image2 data-testid={'second image'} />
      </ImageWrapper>
      <Title>{t('termsAndConditions.title2')}</Title>
      <TextContainer>
        <Text>{t('termsAndConditions.text2-1')}</Text>
        <List marginTop>
          <ListItem>{t('termsAndConditions.list2-1')}</ListItem>
          <ListItem>{t('termsAndConditions.list2-2')}</ListItem>
          <ListItem>{t('termsAndConditions.list2-3')}</ListItem>
        </List>
      </TextContainer>
      <Pagination activeItem={Slides.SECOND} />
      <ButtonsContainer>
        <ButtonBack disabled={disabled} onClick={onBackClick}>
          {t('termsAndConditions.back')}
        </ButtonBack>
        <StyledButton disabled={disabled} onClick={onNextClick}>
          {t('termsAndConditions.continue')}
        </StyledButton>
      </ButtonsContainer>
    </>
  )
}
