import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'
import { PaymentMethodEnum } from 'src/Constants/enums'
import { PaymentErrors } from 'src/Components/PaymentErrorsAlert'

import { ApiError, NameSpace } from '../types'

import { chargePaypalPayment, submitStripePayment } from './api-actions'

export interface PaymentState {
  paymentMethod: PaymentMethodEnum | string
  loading: boolean
  error: ApiError | null
  alertError: null | PaymentErrors
}

const initialState: PaymentState = {
  paymentMethod: '',
  loading: false,
  error: null,
  alertError: null,
}

export const paymentProcess = createSlice({
  initialState,
  name: NameSpace.Payment,
  reducers: {
    setPaymentMethod(state, action: PayloadAction<PaymentMethodEnum>) {
      state.paymentMethod = action.payload
    },
    setPaymentAlertError(state, action: PayloadAction<PaymentErrors | null>) {
      state.alertError = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(chargePaypalPayment.pending, (state) => {
      state.loading = true
    })
    builder.addCase(chargePaypalPayment.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(chargePaypalPayment.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(submitStripePayment.pending, (state) => {
      state.loading = true
    })
    builder.addCase(submitStripePayment.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(submitStripePayment.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })
  },
})

export const { setPaymentMethod, setPaymentAlertError } = paymentProcess.actions
