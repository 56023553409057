import { FunctionComponent, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { capitalize, snakeCaseTransform } from 'src/Helpers/layoutHelpers'
import { ReviewStatus } from 'src/Types/index'
import { transformArrayStrings } from 'src/Constants/functions'
import { variables, colors } from 'src/Styled/variables'

const TrackDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const TrackDetailsTitle = styled.span`
  font-size: ${variables.fontSize10};
  color: ${colors.mainText};
`
const TrackDetailsRow = styled.span`
  display: flex;
  gap: 8px;
`
const TrackDetailsRowLabel = styled.span`
  display: inline-block;
  width: 110px;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.greyTextWhiteBg};
  flex-shrink: 0;
`
const TrackDetailsRowInfo = styled.span<{ color?: string }>`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${({ color }) => (color ? color : colors.mainText)};
`

const MAP_STATUS_TO_COLOR: Record<string, string> = {
  [ReviewStatus.awaiting_review]: colors.messegesYellow1,
  [ReviewStatus.reviewed]: colors.badgeGreen,
  default: colors.mainText,
}

interface TrackToReviewDetailsProps {
  genres?: string[]
  languages?: string[]
  reward?: number
  status?: string
}

export const TrackToReviewDetails: FunctionComponent<
  TrackToReviewDetailsProps
> = ({ genres, languages, reward, status }) => {
  const { t } = useTranslation()

  const memoGenres = useMemo(() => transformArrayStrings(genres), [genres])

  const memoLanguages = useMemo(
    () => transformArrayStrings(languages),
    [languages],
  )

  const currentReward = reward ? reward : '-'

  return (
    <TrackDetailsContainer>
      <TrackDetailsTitle>
        {t('reviewTrackDetails.trackDetails')}
      </TrackDetailsTitle>
      <TrackDetailsRow>
        <TrackDetailsRowLabel>
          {t('reviewTrackDetails.matchedGenres')}
        </TrackDetailsRowLabel>
        <TrackDetailsRowInfo>{memoGenres}</TrackDetailsRowInfo>
      </TrackDetailsRow>
      <TrackDetailsRow>
        <TrackDetailsRowLabel>
          {t('reviewTrackDetails.vocal')}
        </TrackDetailsRowLabel>
        <TrackDetailsRowInfo>{memoLanguages}</TrackDetailsRowInfo>
      </TrackDetailsRow>
      <TrackDetailsRow>
        <TrackDetailsRowLabel>
          {t('reviewTrackDetails.status')}
        </TrackDetailsRowLabel>
        <TrackDetailsRowInfo
          color={
            MAP_STATUS_TO_COLOR[status || 'default'] ||
            MAP_STATUS_TO_COLOR.default
          }
        >
          {status ? capitalize(snakeCaseTransform(status)) : '-'}
        </TrackDetailsRowInfo>
      </TrackDetailsRow>
      <TrackDetailsRow>
        <TrackDetailsRowLabel>
          {t('reviewTrackDetails.reward')}
        </TrackDetailsRowLabel>
        <TrackDetailsRowInfo>{`$${currentReward}`}</TrackDetailsRowInfo>
      </TrackDetailsRow>
    </TrackDetailsContainer>
  )
}
