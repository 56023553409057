import React, { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getCurrencyInfo } from 'src/Redux/auth-process/currencySlice/api-actions'
import { getIsArtist } from 'src/Redux/auth-process/credentialsSlice/selectors'

import { Text, Wrapper } from './styles'

type TopИarBalanceProps = {
  balance?: number
}

const TopBarBalance: React.FunctionComponent<TopИarBalanceProps> = ({
  balance,
}) => {
  const dispatch = useAppDispatch()
  const isArtist = useAppSelector(getIsArtist)

  useEffect(() => {
    void dispatch(getCurrencyInfo())
  }, [dispatch])

  if (!isArtist || !balance) {
    return null
  }
  const value = `$${balance.toFixed()}`
  return (
    <Wrapper>
      <Text>Balance</Text>
      <Text>{value}</Text>
    </Wrapper>
  )
}
export default TopBarBalance
