import styled from 'styled-components'
import SelectField from 'react-select'

import { colors, device } from 'src/Styled/variables'

import { SelectPositionOption } from './props'

export const Select = styled(SelectField<SelectPositionOption>)<{
  fill?: boolean
}>`
  margin-bottom: 16px;
  flex: 1;
  font-family: 'Segoe UI';

  @media (max-width: 576px) {
    margin-right: 0;
    margin-bottom: 8px;
    width: 100%;
  }

  .select-playlist-position__control {
    display: flex;
    align-items: center;
    height: 48px;
    border: 1px solid ${colors.greyStroke};
    border-radius: 8px;
  }
  .select-playlist-position__input {
    display: flex;
    align-items: center;
  }
  .select-playlist-position__single-value {
    padding: 0 2px;
    width: 100%;

    div {
      border: none !important;
    }
  }
  .select-playlist-position__value-container {
    height: 100%;
    padding: 2px 14px !important;
  }

  .select-playlist-position__value-container--has-value {
    height: 100%;
  }
  .select-playlist-position__indicator-separator {
    margin: 0;
    background-color: ${colors.greyStroke};
  }
  .select-playlist-position__indicator {
    padding: 12px;
  }
  .select-playlist-position__control--is-focused {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid ${colors.greyTextBlackBg};

    &:hover {
      border: 1px solid ${colors.greyTextBlackBg};
    }
  }
  .select-playlist-position__menu {
    width: auto;

    @media ${device.mobileMax} {
      position: fixed;
      top: inherit;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
  .select-playlist-position__menu-list {
    display: flex;
    padding: 16px;
    width: 292px;
    height: 192px;
    max-height: 192px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 8px;
    flex-flow: row wrap;

    @media ${device.mobileMax} {
      width: 100%;
      box-shadow: none;
    }
  }
  .select-playlist-position__option {
    display: flex;
    align-items: center;
    padding: 0;
    width: 40px;
    height: 40px;
    flex: 0 1 20%;
    background-color: ${colors.white};
    cursor: pointer;

    div {
      width: 100%;
      height: 40px;
      justify-content: center;
      border-radius: 4px;
    }
    &:last-child {
      border-bottom: none;
    }
    &.select-playlist-position__option--is-selected {
      div {
        background: ${colors.green};
        color: white;
      }
    }
  }
`

export const SelectOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
`
