import { FunctionComponent, useState } from 'react'

import { useTranslation } from 'react-i18next'

import ArrowIcon from 'src/Assets/Svg/arrow.svg?react'
import { FeedFilterDirection, FeedFilterType } from 'src/Types/index'
import { dimensions } from 'src/Styled/variables'

import { ListFilterProps } from './props'
import { ListContent } from './ListContent'

import { StyledPopover, Icon, Container } from './styles'

export const ListFilter: FunctionComponent<ListFilterProps> = ({
  filterType,
  filterDirection,
  setFilterType,
  setFilterDirection,
  isFilterVisible,
}) => {
  const { t } = useTranslation()
  const [isPopupVisible, setIsPopupVisible] = useState(false)

  const onVisibleChange = (value: boolean): void => {
    setIsPopupVisible(value)
  }

  const setFilter = (value: FeedFilterType): void => {
    if (value === filterType) {
      filterDirection === FeedFilterDirection.ASC
        ? setFilterDirection(FeedFilterDirection.DESC)
        : setFilterDirection(FeedFilterDirection.ASC)
    } else {
      setFilterType(value)
    }
    setIsPopupVisible(false)
  }

  return (
    <Container>
      {isFilterVisible && (
        <StyledPopover
          open={isPopupVisible}
          trigger='click'
          placement={
            window.innerWidth <= dimensions.mobileBorder ? 'top' : 'bottom'
          }
          content={
            <ListContent
              setFilter={setFilter}
              filterDirection={filterDirection}
              filterType={filterType}
            />
          }
          overlayInnerStyle={{ borderRadius: 8, padding: 0 }}
          onOpenChange={onVisibleChange}
        >
          {t('tracksFeedPage.sortBy')} {filterType}
          <Icon up={filterDirection === FeedFilterDirection.ASC} active>
            <ArrowIcon />
          </Icon>
        </StyledPopover>
      )}
    </Container>
  )
}
