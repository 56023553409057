import { FC } from 'react'

import { useGetLanguagesForExistingPlaylistsQuery } from 'src/Redux/languagesApi'

import { getNewFilteredLanguages } from '../helpers'
import { List } from '../styles'

import { GroupedItem } from './GroupedItem'
import { SelectedLanguageComponent } from './SelectedLanguage'

import { SelectedLanguage } from '.'

interface Props {
  selectedLanguage: SelectedLanguage | null
  searchValue: string

  targetLanguage?: number
  onClick: (item: SelectedLanguage) => void
  onChange: (id: number) => void
}

export const LanguagesList: FC<Props> = ({
  selectedLanguage,
  searchValue,
  targetLanguage,
  onClick,
  onChange,
}) => {
  const { data: groupedOptions } = useGetLanguagesForExistingPlaylistsQuery()

  if (selectedLanguage) {
    return (
      <SelectedLanguageComponent
        selectedLanguage={selectedLanguage}
        targetLanguage={targetLanguage}
        onClick={() => onClick(selectedLanguage)}
        onChange={onChange}
      />
    )
  }

  const filteredOptions = getNewFilteredLanguages(
    groupedOptions || [],
    searchValue,
  )

  return (
    <List style={{ padding: 0 }}>
      {filteredOptions.map((item) => (
        <GroupedItem
          key={item.label}
          searchValue={searchValue}
          item={item}
          onClick={onClick}
        />
      ))}
    </List>
  )
}
