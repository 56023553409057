import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Button from 'src/Components/Buttons/Button'
import { playlists } from 'src/Router/routes'
import { colors, variables } from 'src/Styled/variables'
import ClockIcon from 'src/Assets/Svg/sandClock.svg?react'

const Wrapper = styled.div`
  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
`
const Text = styled.div`
  color: ${colors.mainText};
  text-align: center;
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

const StyledButton = styled(Button)`
  width: fit-content;
  & > button {
    padding: 0 40px;
  }
`

export const AddPlaylistMessage: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = (): void => {
    navigate(playlists)
  }
  return (
    <Wrapper>
      <ClockIcon width={'56px'} height={'56px'} />
      <Text>{t('reviewsPage.earning')}</Text>
      <StyledButton type='green' onClick={handleClick}>
        {t('reviewsPage.addPlaylists')}
      </StyledButton>
    </Wrapper>
  )
}
