import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'

import CheckboxAntd from 'src/Containers/ui/CheckboxAntd'
import Image6 from 'src/Assets/Svg/image6.svg?react'
import { termsAndConditions as termsAndConditionsLink } from 'src/Constants/links'

import {
  StyledButton,
  ButtonBack,
  ButtonsContainer,
  CheckboxContainer,
  ImageWrapper,
  Link,
  Text,
  TextContainer,
  Title,
} from '../styles'
import { Pagination } from '../Pagination/Pagination'
import { Slides } from '../useSlidesContent'

interface SixthStepProps {
  onNextClick: () => void
  onBackClick: () => void
  disabled?: boolean
}

export const SixthStep: FC<SixthStepProps> = ({
  onNextClick,
  onBackClick,
  disabled,
}) => {
  const [isAnyLanguage, setIsAnyLanguage] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      <ImageWrapper>
        <Image6 data-testid={'sixth image'} />
      </ImageWrapper>
      <Title>{t('termsAndConditions.title6')}</Title>
      <TextContainer edge>
        <Text>{t('termsAndConditions.text6-1')}</Text>
      </TextContainer>
      <CheckboxContainer>
        <CheckboxAntd
          checked={isAnyLanguage}
          onChange={(e) => setIsAnyLanguage(e.target.checked)}
        />
        <Text monospace>
          {t('termsAndConditions.checkboxText')}
          <Link target='_blank' href={termsAndConditionsLink}>
            {t('termsAndConditions.checkboxLink')}
          </Link>
        </Text>
      </CheckboxContainer>
      <Pagination activeItem={Slides.SIXTH} />
      <ButtonsContainer>
        <ButtonBack disabled={disabled} onClick={onBackClick}>
          {t('termsAndConditions.back')}
        </ButtonBack>
        <StyledButton
          disabled={!isAnyLanguage || disabled}
          onClick={onNextClick}
        >
          {t('termsAndConditions.continue')}
        </StyledButton>
      </ButtonsContainer>
    </>
  )
}
