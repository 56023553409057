import { Select, SelectProps } from 'antd'
import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'
import { EIGHTY_PERCENT, FIFTY_PERCENT } from 'src/Constants/constants'
import ChevronDownIcon from 'src/Assets/Svg/chevron_down.svg?react'
import { SmartImage } from 'src/Components/SmartImage'

import { PlaylistOrRejectOption } from './useSelectPlaylistOrRejectOptions'

const matchColor = (percent: number): string => {
  if (percent > EIGHTY_PERCENT) {
    return colors.green
  }
  if (percent > FIFTY_PERCENT) {
    return colors.messegesYellow1
  }

  return colors.messegesRed
}

export const SelectOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const RejectOption = styled(SelectOption)`
  padding: 17.5px 0;
`

export const RejectOptionLabel = styled.p`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
`

export const SelectOptionPlaylist = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const SelectOptionPlaylistCover = styled(SmartImage)`
  width: 40px;
  height: 40px;
  border-radius: 4px;
`

export const SelectOptionPlaylistInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const SelectOptionPlaylistTitle = styled.div`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
  line-height: 150%;
`

export const SelectOptionPlaylistScore = styled.div`
  display: flex;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
  line-height: 150%;
`

export const SelectOptionPlaylistScoreLabel = styled.div`
  margin-right: 4px;
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
`
export const StyledSelectContainer = styled.div`
  margin-bottom: 24px;
`

export const SelectOptionPercents = styled.span<{ percent: number }>`
  display: flex;
  align-items: center;
  margin-right: 8px;
  color: ${({ percent }) => matchColor(percent)};
`
type Props = SelectProps<number, PlaylistOrRejectOption>

export const StyledSelect = styled(({ className, ...props }: Props) => (
  <Select
    virtual={false}
    suffixIcon={<ChevronDownIcon />}
    popupClassName={className as string}
    rootClassName={className as string}
    {...props}
  />
))`
  width: 100%;
  border: 1px solid ${colors.greyStroke};
  &:hover,
  &:focus-visible {
    border-color: ${colors.greyStroke};
    outline: none;
  }
  border-radius: 8px;
  height: fit-content;
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 62px;
  }
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: ${colors.mainText};
  }
  &.ant-select:hover .ant-select-arrow:not(:last-child) {
    opacity: 1;
  }
  &.ant-select-open .ant-select-arrow:not(:last-child) {
    transform: rotate(-180deg);
    transition: 300ms linear all;
  }
  &:not(.ant-select-open) .ant-select-arrow:not(:last-child) {
    transition: 300ms linear all;
  }
  &.ant-select-open .ant-select-arrow {
    transform: rotate(-180deg);
    transition: 300ms linear all;
  }
  &:not(.ant-select-open) .ant-select-arrow {
    transition: 300ms linear all;
  }
  &.ant-select-outlined:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border-color: ${colors.greyStroke};
  }
  &.ant-select-outlined:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer)
    .ant-select-selector {
    border-color: ${colors.greyStroke};
    box-shadow: none;
  }
  &.ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: transparent;
  }
  &.ant-select-dropdown {
    position: absolute;
    &::before {
      display: none;
    }
  }

  @media screen and (max-width: 576px) {
    .ant-select-selection-item {
      #SelectOptionPercents {
        display: none;
      }
    }
  }
  &.ant-select-dropdown
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: transparent;
  }
  &.ant-select-dropdown {
    bottom: 0 !important;
    left: 0 !important;
    top: 100% !important;
  }

  @media ${device.mobileMax} {
    &.ant-select-dropdown {
      position: fixed !important;
      bottom: 0 !important;
      width: 100vw !important;
      left: 0 !important;
      top: 100% !important;
      transform: translate(0%, -100%);
      padding-bottom: 20px;
    }
  }
`
