export const {
  VITE_APP_SERVER,
  VITE_APP_SENTRY_DSN,
  VITE_APP_PAYPAL_ENVIRONMENT,
  VITE_APP_GTM_CONTAINER,
  VITE_APP_INTERCOM_APP_ID,
  VITE_APP_SPOTIFY_AUTH_API,
  VITE_APP_RE_CAPTCHA_SITE_KEY,
  VITE_APP_ADMIN_EMAIL,
  VITE_APP_PAYPAL_CLIENT_KEY_SANDBOX,
  VITE_APP_TIKTOK_AUTH_API,
  VITE_APP_SOCKET_URL,
  VITE_APP_TIKTOK_SERVER,
  VITE_APP_GA_CONTAINER,
  VITE_APP_REACH_STEP_BUDGET_TIKTOK_CAMPAIGN,
  VITE_APP_GROWTHBOOK_API_HOST,
  VITE_APP_GROWTHBOOK_CLIENT_KEY,
  VITE_APP_IS_PAYPAL_TRANSACTIONS_ENABLED,
  VITE_APP_DATE_IS_NEW_USER_REMOVE_APROVALL_STEP,
  VITE_APP_ENABLED_DEV_MODE_GROWTHBOOK,
  VITE_APP_SOURCEBUSTER_DOMAIN,
  VITE_APP_DATE_IS_NEW_USER_CHECKOUT_TIMER,
  VITE_APP_DATE_IS_NEW_USER_HIDE_LANGUAGE_MATCHING_EXP,
  VITE_APP_DATE_IS_NEW_USER_REMOVE_PAYPAL_EXP,
  VITE_APP_DATE_IS_NEW_USER_MIN_BUDGET_DECREASE_EXP,
  VITE_APP_IS_NEW_ARTIST_UI,
} = import.meta.env as Record<string, string>

export const APP = {
  SERVER: VITE_APP_SERVER,
  SENTRY_DSN: VITE_APP_SENTRY_DSN,
  GTM_CONTAINER: VITE_APP_GTM_CONTAINER,
  INTERCOM_APP_ID: VITE_APP_INTERCOM_APP_ID,
  SPOTIFY_AUTH_API: VITE_APP_SPOTIFY_AUTH_API,
  paypal_environment: VITE_APP_PAYPAL_ENVIRONMENT, // sandbox or production
  admin_email: VITE_APP_ADMIN_EMAIL && VITE_APP_ADMIN_EMAIL.split(','),
  SOCKET_URL: VITE_APP_SOCKET_URL,
  PAYPAL_CLIENT_KEY_SANDBOX: VITE_APP_PAYPAL_CLIENT_KEY_SANDBOX,
  TIKTOK_SERVER: VITE_APP_TIKTOK_SERVER,
  TIKTOK_AUTH_API: VITE_APP_TIKTOK_AUTH_API,
  IS_PAYPAL_TRANSACTIONS_ENABLED:
    VITE_APP_IS_PAYPAL_TRANSACTIONS_ENABLED === 'true',
  ENABLED_DEV_MODE_GROWTHBOOK: VITE_APP_ENABLED_DEV_MODE_GROWTHBOOK === 'true',
  IS_NEW_ARTIST_UI: VITE_APP_IS_NEW_ARTIST_UI === 'true',
  GA_CONTAINER: VITE_APP_GA_CONTAINER,
  RE_CAPTCHA_SITE_KEY: VITE_APP_RE_CAPTCHA_SITE_KEY,
  GROWTHBOOK_API_HOST: VITE_APP_GROWTHBOOK_API_HOST,
  GROWTHBOOK_CLIENT_KEY: VITE_APP_GROWTHBOOK_CLIENT_KEY,
  REACH_STEP_BUDGET_TIKTOK_CAMPAIGN: Number(
    VITE_APP_REACH_STEP_BUDGET_TIKTOK_CAMPAIGN,
  ),
  SOURCEBUSTER_DOMAIN: VITE_APP_SOURCEBUSTER_DOMAIN,
  DATE_IS_NEW_USER_REMOVE_APROVALL_STEP:
    VITE_APP_DATE_IS_NEW_USER_REMOVE_APROVALL_STEP,
  DATE_IS_NEW_USER_CHECKOUT_TIMER: VITE_APP_DATE_IS_NEW_USER_CHECKOUT_TIMER,
  DATE_IS_NEW_USER_HIDE_LANGUAGE_MATCHING_EXP:
    VITE_APP_DATE_IS_NEW_USER_HIDE_LANGUAGE_MATCHING_EXP,
  DATE_IS_NEW_USER_REMOVE_PAYPAL_EXP:
    VITE_APP_DATE_IS_NEW_USER_REMOVE_PAYPAL_EXP,
  DATE_IS_NEW_USER_MIN_BUDGET_DECREASE_EXP:
    VITE_APP_DATE_IS_NEW_USER_MIN_BUDGET_DECREASE_EXP,
}
