import { useEffect, useState } from 'react'

import axios from 'axios'

interface SpotifyIFrameResponse {
  loading: boolean
  error: boolean
  iFrame: string
}

type SpotifyResponse = {
  html: string
}
export const useSpotifyIFrame = (id: string): SpotifyIFrameResponse => {
  const [loading, setLoading] = useState(true)
  const [iFrame, setIFrame] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  useEffect(() => {
    axios<SpotifyResponse>(
      `https://open.spotify.com/oembed?url=https://open.spotify.com/track/${id}`,
    )
      .then(({ data }) => {
        setIFrame(data.html.replace('152', '100%'))
        setLoading(false)
        return data
      })
      .catch((_err) => {
        setError(true)
        setLoading(false)
      })
  }, [id])
  return { loading, iFrame, error }
}
