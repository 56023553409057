import { FunctionComponent } from 'react'

import { Route, Routes } from 'react-router-dom'

import { CampaignsListPage } from 'src/Pages/Artist/CampaignsListPage'
import { SpotifyCampaignDetailsPage } from 'src/Pages/Artist/SpotifyCampaignDetailsPage'
import { SpotifySchedulePage } from 'src/Pages/Artist/SpotifySchedulePage'
import { TiktokCampaignDetailsPage } from 'src/Pages/Artist/TiktokCampaignDetailsPage'
import { TiktokSchedulePage } from 'src/Pages/Artist/TiktokSchedulePage'

export enum ArtistRoutesEnum {
  Root = '/',
  CampaignsList = '/campaigns',
  TiktokDetails = '/campaigns/details',
  SpotifyDetails = '/campaigns/view',
  SpotifySchedule = '/campaigns/schedule',
  TiktokSchedule = '/campaigns/scheduleTikTok',
  New = '/new',
  Settings = '/settings',
  ReferAndEarn = '/refer-and-earn',
  Balance = '/balance',
  Subscription = '/subscription',
  Admin = '/admin',
}

export const ArtistComponentsNewUI: FunctionComponent = () => (
  <Routes>
    {/* <Route*/}
    {/*  Component={CampaignResults}*/}
    {/*  path={`${campaignsList}${campaignResults}`}*/}
    {/* >*/}
    {/*  <Route path=':campaignID' Component={CampaignResults} />*/}
    {/* </Route>*/}

    {/* <Route path={`${campaignsList}${details}`}>*/}
    {/*  <Route path=':campaignID' Component={TikTokCampaignDetails} />*/}
    {/*  <Route path=':campaignID/:videoID' Component={TiktokReview} />*/}
    {/* </Route>*/}

    {/* <Route Component={ReferAndEarn} path={referAndEarn} />*/}

    {/* <Route Component={ArtistBalancePage} path={balance} />*/}

    {/* <Route Component={Subscriptions} path={subscription}></Route>*/}
    {/* <Route path={`${subscription}/pay`} Component={PaySubscription} />*/}
    {/* <Route*/}
    {/*  path={`${subscription}/pay/success`}*/}
    {/*  Component={SuccessPaymentSubscription}*/}
    {/* />*/}
    {/* <Route path={`${subscription}/cancel`} Component={CancelSubscription} />*/}

    {/* <Route*/}
    {/*  Component={ScheduleSpotifyWrapper}*/}
    {/*  path={`${campaignsList}${scheduleCampaign}`}*/}
    {/* >*/}
    {/*  <Route path=':number' Component={ScheduleSpotifyWrapper} />*/}
    {/* </Route>*/}

    {/* <Route*/}
    {/*  Component={ScheduleTiktokWrapper}*/}
    {/*  path={`${campaignsList}${scheduleCampaignTikTok}`}*/}
    {/* >*/}
    {/*  <Route path=':number' Component={ScheduleTiktokWrapper} />*/}
    {/* </Route>*/}

    {/* <Route Component={Settings} path={settings}>*/}
    {/*  <Route path=':page' Component={Settings} />*/}
    {/* </Route>*/}

    {/* <Route*/}
    {/*  Component={CreateCampaignPage}*/}
    {/*  path={`${campaignsList}${createCampaign}`}*/}
    {/* />*/}
    {/* <Route*/}
    {/*  path={`${campaignsList}${createCampaign}/:platform`}*/}
    {/*  Component={SearchNewCampaignByPlatform}*/}
    {/* />*/}
    {/* <Route*/}
    {/*  path={`${createdFirst}/:campaignType`}*/}
    {/*  Component={SuccessCreatedFirstCampaign}*/}
    {/* />*/}
    {/* <Route*/}
    {/*  path={`${createdFirst}/:campaignType${feedback}`}*/}
    {/*  Component={FirstFeedback}*/}
    {/* />*/}

    {/* <Route Component={ExpiredLinkPage} path={errors} />*/}

    {/* {APP.admin_email.includes(String(email)) && (*/}
    {/*  <Route Component={AdminPage} path={admin} />*/}
    {/* )}*/}

    <Route
      Component={TiktokSchedulePage}
      path={`${ArtistRoutesEnum.TiktokSchedule}/:campaignID`}
    />
    <Route
      Component={SpotifySchedulePage}
      path={`${ArtistRoutesEnum.SpotifySchedule}/:campaignID`}
    />
    <Route
      Component={SpotifyCampaignDetailsPage}
      path={`${ArtistRoutesEnum.SpotifyDetails}/:campaignID`}
    />
    <Route
      Component={TiktokCampaignDetailsPage}
      path={`${ArtistRoutesEnum.TiktokDetails}/:campaignID`}
    />
    <Route Component={CampaignsListPage} path='*' />
    <Route Component={CampaignsListPage} path={ArtistRoutesEnum.Root} />
    <Route
      Component={CampaignsListPage}
      path={ArtistRoutesEnum.CampaignsList}
    />
  </Routes>
)
