import { FC, PropsWithChildren, useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { PageLayout } from 'src/Components/PageLayout'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'
import {
  getSidebarVisibility,
  hideSidebar,
  toggleSidebarVisibility,
} from 'src/Redux/pageLayout-process'
import { useAppDispatch } from 'src/Hooks/redux'

import { SidebarContainer } from './Components/SidebarContainer'
import { HeaderContainer } from './Components/HeaderContainer'

const routesWithoutHeader = [
  ArtistRoutesEnum.TiktokSchedule,
  ArtistRoutesEnum.SpotifySchedule,
]
const routesWithCollapsedSidebar = [
  ArtistRoutesEnum.TiktokSchedule,
  ArtistRoutesEnum.SpotifySchedule,
]

export const PageLayoutContainer: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch()
  const isSidebarCollapsed = useSelector(getSidebarVisibility)
  const { pathname } = useLocation()

  const isHeaderHidden = Boolean(
    routesWithoutHeader.find((route) => pathname.includes(route)),
  )

  const onSidebarToggleClick = (): void => {
    dispatch(toggleSidebarVisibility())
  }

  useEffect(() => {
    const isPageWithCollapsedSidebar = routesWithCollapsedSidebar.find(
      (route) => pathname.includes(route),
    )

    if (isPageWithCollapsedSidebar) {
      dispatch(hideSidebar())
    }
  }, [dispatch, pathname])

  return (
    <PageLayout
      Header={HeaderContainer}
      Sidebar={SidebarContainer}
      isHeaderHidden={isHeaderHidden}
      onSidebarToggleClick={onSidebarToggleClick}
      isSidebarCollapsed={isSidebarCollapsed}
    >
      {children}
    </PageLayout>
  )
}
