import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

const wordsBlackListApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getWordsBlackList: builder.query<string[], void>({
      query: () => `${APP.SERVER}/review/blacklist`,
    }),
  }),
  overrideExisting: false,
})
export const { useGetWordsBlackListQuery } = wordsBlackListApi
