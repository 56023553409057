import { FunctionComponent, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CuratorActivationAlert } from 'src/Components/CuratorActivationAlert'
import { getIsNewFeed } from 'src/Redux/auth-process/userSlice/selectors'
import { setIsNewFeed } from 'src/Redux/auth-process/userSlice/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'

import { TrackList } from './components'

import { ContainerTracksFeed, TracksFeedPageTitle } from './styles'

/**
 * Feed reviews page. Curator's side.
 */
const TracksFeed: FunctionComponent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isNewFeed = useSelector(getIsNewFeed)

  useEffect(() => {
    if (isNewFeed) {
      void dispatch(setIsNewFeed())
    }
  }, [isNewFeed, dispatch])

  return (
    <ContainerTracksFeed>
      <TracksFeedPageTitle>{t('tracksFeedPage.pageTitle')}</TracksFeedPageTitle>
      <CuratorActivationAlert />

      <TrackList />
    </ContainerTracksFeed>
  )
}

export default TracksFeed
