import {
  ReviewStatus,
  MatchedPlaylistReview as MatchedPlaylist,
  ArtistFeedback,
} from 'src/Types/index'

export enum Tabs {
  all = 'all',
  awaiting_review = 'awaiting_review',
  reviewed = 'reviewed',
  missed = 'missed',
}

export interface Campaign {
  id: number
  artist_name: string
  start_date: Date
  end_date: Date
  track_name: string
  track_image: string
  matched_playlists: MatchedPlaylist[]
}

export interface Review {
  id: number
  curator_rating: number
  potential_reward: string
  rank: number
  review_feedback: ArtistFeedback
  review_status: ReviewStatus
  campaign: Campaign
}

export interface SkipMobileProps {
  campaign_id: number | string
}

export interface ReviewMobileProps {
  campaign_id: number
  isReviewed?: boolean
}
