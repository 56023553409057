import styled from 'styled-components'
import { Popover } from 'antd'

import { variables, colors, device } from 'src/Styled/variables'

export const Container = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  flex-direction: row;

  @media ${device.mobileMax} {
    position: fixed;
    top: inherit;
    bottom: 10px;
    left: 10px;
  }
`

export const StyledPopover = styled(Popover)`
  padding: 9.5px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: ${colors.white};
  color: ${colors.greyTextWhiteBg};
  border-radius: 20px;
  border: 1px solid ${colors.greyStroke};
  cursor: pointer;
  transition: 0.15s;

  &.ant-popover-open {
    box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .ant-popover-inner {
    border-radius: 8px;
    padding: 0;
  }
`

export const RefreshButton = styled.div`
  margin-right: 16px;
  padding: 9.5px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: ${colors.greyBg};
  color: ${colors.greyTextWhiteBg};
  border-radius: 20px;
  border: 1px solid ${colors.greyStroke};
  cursor: pointer;
  transition: 0.15s;

  &.ant-popover-open {
    box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .ant-popover-inner {
    border-radius: 8px;
    padding: 0;
  }
`

export const FilterContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  width: 240px;
  transition: 0.15s linear all;
`

export const FilterContentItem = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  cursor: pointer;
  transition: 0.15s linear all;
`

export const IconStatic = styled.div`
  margin-left: 11px;
  width: 12px;
  height: 12px;
`

export const Icon = styled(IconStatic)<{ active?: boolean; up?: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  transform: ${({ up }) => (up ? 'rotate(90deg)' : 'rotate(-90deg)')};
  transition: 0.15s linear all;
`
