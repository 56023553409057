import { FC, useState, Suspense } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'
import Modal from 'src/Components/Modal'
import {
  getAreTermsAccepted,
  getIsNewTerm,
  getUserSelector,
} from 'src/Redux/auth-process/userSlice/selectors'
import { activateCurator } from 'src/Redux/auth-process/userSlice/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'
import { LoadingSpinner } from 'src/Containers/ui'

import { AccountReactivation } from '../AccountReactivation'
import TermsAndConditions from '../TermsAndConditions'

import { AlertButton, AlertText, AlertTitle } from './styles'

export const CuratorActivationAlert: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [activatingModal, setActivatingModal] = useState(false)

  const { isDeactivated } = useSelector(getUserSelector)
  const isNewTerm = useSelector(getIsNewTerm)
  const areTermsAccepted = useSelector(getAreTermsAccepted)

  const onCloseModal = (): void => {
    setActivatingModal(false)
  }

  const onActivateBack = (): void => {
    void dispatch(activateCurator())
    setActivatingModal(true)
  }

  return (
    <>
      {isDeactivated && (
        <Alert color='danger' icon>
          <AlertText>
            <AlertTitle>{t('reviewsPage.accountDeactivatedTitle')}</AlertTitle>
            <div>{t('reviewsPage.accountDeactivatedMessage')}</div>
          </AlertText>
          <AlertButton onClick={onActivateBack}>
            {t('reviewsPage.accountDeactivatedButton')}
          </AlertButton>
        </Alert>
      )}
      {activatingModal && (
        <Modal
          title={t('reviewsPage.reactivationModalHeader')}
          modalHeaderGreen
          isModalVisible={activatingModal}
          closeModal={onCloseModal}
          fullScreenMobileMode
        >
          <AccountReactivation onCloseSuccess={onCloseModal} />
        </Modal>
      )}

      {isNewTerm && !areTermsAccepted && (
        <Suspense fallback={<LoadingSpinner />}>
          <TermsAndConditions />
        </Suspense>
      )}
    </>
  )
}
