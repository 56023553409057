import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { ArtistFeedback } from 'src/Types'

interface SendFeedReviewBody {
  campaign_id: string
  position: number

  comment: string
  recommendations: string | null
  value: { id: number; type: string }
}

export interface FeedReviewPlaylist {
  id: number
  score: number
  rank: number
  matchedPercent: number
  playlistName: string
  playlistCover: string
  followers: number
}

export interface FeedReviewReview {
  comment: string
  recommendations: string
  reviewFeedback: ArtistFeedback
  potentialReward: string
  curatorRating: number
  reservationTime: string
  availableAt: string
  campaignId: number
  complainRead: boolean
  createdAt: string
  curatorId: number
  id: number
  playlistId: number
  rating: number
  rejectReasonId: number
  reviewDate: string
  reviewStatus: string
  rewardReceived: number
  reviewExtensionCount: number
}

export interface FeedReviewTrack {
  id: number
  artistName: string
  trackName: string
  trackImage: string
  trackUrl: string
  languages: string[]
  genres: string[]
}

export interface FeedReviewDetails {
  addedToPlaylist: FeedReviewPlaylist | null
  review: FeedReviewReview
  track: FeedReviewTrack
}

export interface PendingFeedReviewDetails extends FeedReviewDetails {
  hasHighRejectionRate: boolean
  matchedPlaylists: Array<FeedReviewPlaylist>
}

export interface ProcessedFeedReviewDetails extends FeedReviewDetails {
  addedToPlaylist: FeedReviewPlaylist | null
}

type ExtendReviewTimeData = FeedReviewDetails['review']

const trackFeedDetailsApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getPendingTrackDetails: builder.query<PendingFeedReviewDetails, string>({
      query: (campaignId: string) =>
        `${APP.TIKTOK_SERVER}/curator/reviews/pending/by-campaign/${campaignId}`,
      providesTags: ['TrackFeedDetails'],
    }),
    extendReviewTime: builder.mutation<ExtendReviewTimeData, number>({
      query: (reviewId: number) => ({
        url: `${APP.TIKTOK_SERVER}/curator/reviews/${reviewId}/extend-time`,
        method: 'PATCH',
      }),
      invalidatesTags: ['TrackFeedDetails'],
    }),
    getProcessedFeedTrackDetails: builder.query<
      ProcessedFeedReviewDetails,
      string
    >({
      query: (campaignId: string) =>
        `${APP.TIKTOK_SERVER}/curator/reviews/processed/by-campaign/${campaignId}`,
    }),
    reserveReview: builder.mutation<string, number>({
      query: (campaignId: number) => ({
        url: `${APP.TIKTOK_SERVER}/curator/reviews`,
        body: { campaignId },
        method: 'POST',
        responseHandler: (response) => response.text(),
      }),
    }),
    skipReview: builder.mutation<string, string>({
      query: (campaignId: string) => ({
        url: `${APP.SERVER}/review/feed-skip/${campaignId}`,
        method: 'POST',
        responseHandler: (response) => response.text(),
      }),
    }),
    sendFeedReview: builder.mutation<string, SendFeedReviewBody>({
      query: (body) => ({
        body,
        url: `${APP.SERVER}/review/feed`,
        method: 'POST',
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetPendingTrackDetailsQuery,
  useExtendReviewTimeMutation,
  useReserveReviewMutation,
  useSkipReviewMutation,
  useSendFeedReviewMutation,
  useGetProcessedFeedTrackDetailsQuery,
} = trackFeedDetailsApi
