import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import { RootState } from '..'
import { NameSpace } from '../types'

interface SoundLinkHintInterface {
  closedDate: string
  closedState: boolean
}

interface AlertState {
  approvedAlertsIds: Array<number>
  soundLinkHint: SoundLinkHintInterface
}

const initialState: AlertState = {
  approvedAlertsIds: [],
  soundLinkHint: {
    closedDate: new Date().toISOString() || '',
    closedState: true,
  },
}

export const alertProcess = createSlice({
  initialState,
  name: NameSpace.Alert,
  reducers: {
    setApprovedAlertsList: (state, { payload }: PayloadAction<number[]>) => {
      state.approvedAlertsIds = payload
    },
    setSoundLinkHint: (
      state,
      { payload }: PayloadAction<SoundLinkHintInterface>,
    ) => {
      state.soundLinkHint = payload
    },
  },
})

export const { setApprovedAlertsList, setSoundLinkHint } = alertProcess.actions

const getState = (state: RootState): AlertState => state.alerts

export const getApprovedAlertsIds = createDraftSafeSelector(
  getState,
  ({ approvedAlertsIds }) => approvedAlertsIds,
)

export const getSoundLinkHint = createDraftSafeSelector(
  getState,
  ({ soundLinkHint }) => soundLinkHint,
)
