import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'
import { ReviewStatus } from 'src/Types/index'

const rewardColor = (status: ReviewStatus): string => {
  switch (status) {
    case ReviewStatus.awaiting_review:
      return colors.greyTextWhiteBg
    case ReviewStatus.missed:
      return colors.messegesRed
    case ReviewStatus.reviewed:
      return colors.greyTextWhiteBg
    default:
      return colors.greyTextWhiteBg
  }
}

export const ReviewedListItemContainer = styled.div`
  padding-bottom: 2px;
  width: 100%;
  height: 130px;

  @media ${device.mobileMax} {
    height: auto;
  }
  &:last-child {
    margin-bottom: 24px;
  }
`

export const ContentContainer = styled.div`
  padding: 0 24px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 100%;
  background: ${colors.greyBg};
  border-radius: 4px;

  @media ${device.mobileMax} {
    padding: 16px;
    align-items: flex-start;
  }
`

export const AvatarContainer = styled.div``

export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: 100%;
  background: ${colors.greyBg};

  @media ${device.mobileMax} {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 32px;
    width: 100%;
  }
`

export const MobileSectionTitle = styled.span`
  display: none;
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight400};
  line-height: 15px;
  color: ${colors.mainText};

  @media ${device.mobileMax} {
    display: inline-block;
  }
`

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
`

export const TrackMainInfo = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  width: 230px;
`

export const TrackTitle = styled.span`
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};

  @media ${device.mobileMax} {
    margin-bottom: 10px;
  }
`

export const TrackAuthor = styled.span`
  font-size: ${variables.fontSize10};
  color: ${colors.mainText};

  @media ${device.mobileMax} {
    margin-bottom: 10px;
    font-size: ${variables.fontSize14};
  }
`

export const ProgressContainer = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  min-width: 170px;
  flex: 1;

  @media ${device.mobileMax} {
    margin-bottom: 8px;
  }
`

export const RewardContainer = styled.span`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.greyTextWhiteBg};
`

export const RewardValue = styled(RewardContainer)<{ status: ReviewStatus }>`
  margin-left: 4px;
  color: ${({ status }) => rewardColor(status)};
`

export const ButtonsContainer = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 160px;
`
