import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'

import { colors } from 'src/Styled/variables'
import SearchIcon from 'src/Assets/Svg/search.svg?react'

import { CampaignsListTableActionProps } from '..'

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const StyledInput = styled('input')`
  background: ${colors.errorBg};
  border: 1px solid ${colors.greyStroke};
  box-sizing: border-box;
  border-radius: 20px;
  padding: 0px 16px;
  height: 40px;
  width: 100%;
  outline: none;

  &::placeholder {
    color: ${colors.greyTextWhiteBg};
  }
  &:focus {
    color: ${colors.mainText};
    outline: none;
  }
`

export const SearchIconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  background-color: ${colors.errorBg};
`

type Props = Pick<CampaignsListTableActionProps, 'search' | 'setSearch'>

export const SearchCampaign: FC<Props> = ({ setSearch, search }) => {
  const { t } = useTranslation()

  const debounced = useDebouncedCallback((value: string) => {
    setSearch(value)
  }, 100)
  return (
    <InputWrapper>
      <StyledInput
        value={search}
        placeholder={String(t('campaignsPage.searchCampaign'))}
        onChange={(e) => debounced(e.target.value)}
      />
      {!search && (
        <SearchIconWrapper>
          <SearchIcon width={16} height={16} />
        </SearchIconWrapper>
      )}
    </InputWrapper>
  )
}
