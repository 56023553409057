import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'
import IconSuccessfully from 'src/Assets/Svg/successfully.svg?react'

import { AccountReactivationProps } from './props'

import {
  AccountReactivationContainer,
  ButtonsContainer,
  Title,
  Text,
  Circle,
  Block,
} from './styles'

export const AccountReactivation: FunctionComponent<
  AccountReactivationProps
> = ({ onCloseSuccess }) => {
  const { t } = useTranslation()

  return (
    <AccountReactivationContainer>
      <Block>
        <Circle>
          <IconSuccessfully width={48} height={48} />
        </Circle>

        <Title>{t('reviewsPage.reactivationModalTitle')}</Title>
        <Text>
          {t('reviewsPage.reactivationModalText1')}&nbsp;
          {t('reviewsPage.reactivationModalText2')}
        </Text>

        <ButtonsContainer>
          <Button type='white' onClick={onCloseSuccess}>
            {t('reviewsPage.reactivationModalButton')}
          </Button>
        </ButtonsContainer>
      </Block>
    </AccountReactivationContainer>
  )
}
