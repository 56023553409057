import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Alert from 'src/Components/Alert'
import { useAppSelector } from 'src/Hooks/redux'
import { getIsLimitReached } from 'src/Redux/feed-process/selectors'
import { variables } from 'src/Styled/variables'

const AlertText = styled.div`
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
`

export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
`
export const TracksFeedAlert: FC = () => {
  const { t } = useTranslation()
  const isLimitReached = useAppSelector(getIsLimitReached)

  if (!isLimitReached) {
    return null
  }
  return (
    <Alert color='warning' icon unclosable>
      <AlertText>
        <AlertTitle>{t('tracksFeedPage.limitReachTitle')}</AlertTitle>
        <div>{t('tracksFeedPage.limitReachText')}</div>
      </AlertText>
    </Alert>
  )
}
