import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Image4 from 'src/Assets/Svg/image4.svg?react'

import {
  ButtonBack,
  ButtonsContainer,
  ImageWrapper,
  List,
  ListItem,
  Paragraph,
  Text,
  TextContainer,
  Title,
  StyledButton,
} from '../styles'
import { Slides } from '../useSlidesContent'
import { Pagination } from '../Pagination/Pagination'

interface FourthStepProps {
  onNextClick: () => void
  onBackClick: () => void
  disabled?: boolean
}

export const FourthStep: FC<FourthStepProps> = ({
  onNextClick,
  onBackClick,
  disabled,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <ImageWrapper>
        <Image4 data-testid={'fourth image'} />
      </ImageWrapper>
      <Title>{t('termsAndConditions.title4')}</Title>
      <TextContainer>
        <Paragraph>
          <Text monospace>{t('termsAndConditions.text4-1')}</Text>
        </Paragraph>
        <List marginTop>
          <ListItem>{t('termsAndConditions.list4-1')}</ListItem>
          <ListItem>{t('termsAndConditions.list4-2')}</ListItem>
          <ListItem>{t('termsAndConditions.list4-3')}</ListItem>
          <ListItem>{t('termsAndConditions.list4-4')}</ListItem>
          <ListItem>{t('termsAndConditions.list4-5')}</ListItem>
        </List>
      </TextContainer>
      <Pagination activeItem={Slides.FOURTH} />
      <ButtonsContainer>
        <ButtonBack disabled={disabled} onClick={onBackClick}>
          {t('termsAndConditions.back')}
        </ButtonBack>
        <StyledButton disabled={disabled} onClick={onNextClick}>
          {t('termsAndConditions.continue')}
        </StyledButton>
      </ButtonsContainer>
    </>
  )
}
