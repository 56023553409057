import styled from 'styled-components'
import { Tabs } from 'antd'

import { variables, colors, device } from 'src/Styled/variables'

const PADDING_BOTTOM = 24
export const Container = styled.div<{ bottomPadding?: boolean }>`
  position: relative;
  margin-top: 16px;
  margin-bottom: 56px;
  width: 100%;
  background: ${colors.white};
  border-radius: 4px;
  padding: 24px 24px 0px 24px;

  @media ${device.mobileMax} {
    padding: 24px 24px
      ${({ bottomPadding }) => (bottomPadding ? PADDING_BOTTOM : 0)}px 24px;
    position: static;
    background: transparent;
    padding: 0;
  }
`

export const TabTitles = styled(Tabs)`
  display: flex;
  margin-bottom: 20px;

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }

  .ant-tabs-nav .ant-tabs-nav-operations,
  .div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }

  .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-nav-list::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 2px solid ${colors.greyStroke};
  }

  .ant-tabs-tab .ant-tabs-tab-btn,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${colors.mainText} !important;
    text-shadow: none;
    font-size: ${variables.fontSize14};
    font-weight: ${variables.fontWeight400};
    line-height: 21px;
  }

  .ant-tabs-ink-bar {
    background-color: ${colors.green} !important;
  }

  .ant-tabs-tab {
    padding: 12px 24px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  @media (max-width: 576px) {
    position: relative;
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const ListLimitMessage = styled.span`
  display: block;
  margin-top: 31.5px;
  margin-bottom: 33.5px;
  font-size: ${variables.fontSize14};
  text-align: center;
  color: ${colors.greyTextWhiteBg};
  text-align: center;
`
