import { FC, useCallback, useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { FullScreenModal } from 'src/Components/FullScreenModal'
import { GenresList } from 'src/Components/GenresList'
import { getGenres } from 'src/Redux/genres-process/selectors'
import { Genre, ProperGenreOrLanguage } from 'src/Types'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { ModalTitle } from 'src/Containers/ScheduleSpotifyWrapper/components/ModalsContainer/ModalTitle'
import { ListWrapper } from 'src/Containers/ScheduleSpotifyWrapper/components/ModalsContainer/styles'
import { convertGenres } from 'src/Containers/ScheduleSpotifyWrapper/components/ModalsContainer/helpers'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { GenresModalFooter } from './GenresModalFooter'

interface Props {
  genres?: ProperGenreOrLanguage[]
  onSave: (genres: Genre[]) => void
}

export const GenresModal: FC<Props> = ({ genres = [], onSave }) => {
  const { setSearch } = useCustomSearchParams()

  const { t } = useTranslation()
  const allGenres = useSelector(getGenres)
  const [value, setValue] = useState<string>('')
  const [selectedGenres, setSelectedGenres] = useState<Genre[]>([])
  const handleClose = (): void => {
    setSearch({ step: ScheduleSteps.SETTINGS, modal: null })
  }

  useEffect(() => {
    if (genres.length) {
      setSelectedGenres(convertGenres(genres))
    }
  }, [genres, genres.length])

  const handleClickGenre = (id: number): void => {
    setSelectedGenres((prev) => {
      const isGenreSelected = prev.some((item) => item.id === id)
      if (isGenreSelected) {
        return prev.filter((item) => item.id !== id)
      }
      const genre = allGenres.find((item) => item.id === id)
      if (!genre) {
        return prev
      }
      return [...prev, genre]
    })
  }

  const handleSetValue = useCallback((text: string) => {
    setValue(text)
  }, [])

  return (
    <FullScreenModal onCancel={handleClose} open prefixCls='genres'>
      <ModalTitle
        title={t('scheduleCampaignPage.trackGenres')}
        placeholder={t('scheduleCampaignPage.enterGenreName')}
        setValue={handleSetValue}
      />
      <ListWrapper>
        <GenresList
          searchValue={value}
          onClick={handleClickGenre}
          genres={allGenres}
          selectedGenres={selectedGenres}
        />
      </ListWrapper>
      <GenresModalFooter
        onClick={() => onSave(selectedGenres)}
        isDisabled={!selectedGenres.length}
      />
    </FullScreenModal>
  )
}
