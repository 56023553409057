import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const ContainerTracksFeed = styled.div`
  margin: 0 auto;
  //padding: 16px 40px 16px 40px;
  //max-width: 1120px;

  @media ${device.mobileMax} {
    padding: 0;
  }
`

export const TracksFeedPageTitle = styled.h3`
  margin-bottom: 20px;
  font-size: ${variables.fontSize20};
  line-height: 20px;
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
`
