import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

import Button from 'src/Components/Buttons/Button'
import { CloseIconWhite } from 'src/Assets/Svg'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useSkipReviewMutation } from 'src/Redux/TrackFeedDetailsApi'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { DEBOUNCE_DELAY_1000 } from 'src/Constants/constants'

import { onReviewSkippedEvent } from '../TrackList/helpers'

import {
  SkipReviewModalHeader,
  SkipReviewModalText,
  ModalButtons,
  StyledModal,
  SkipReviewModalWrapper,
} from './styles'

interface SkipReviewModalProps {
  onReload: () => void
}

const SkipReviewModal: FC<SkipReviewModalProps> = ({ onReload }) => {
  const { t } = useTranslation()
  const userId = useAppSelector(getUserId)
  const { search, setSearch } = useCustomSearchParams()
  const [skipFeedReview, { isLoading }] = useSkipReviewMutation()
  const showModal = search.get('skip')

  const handleCancel = (): void => {
    setSearch({ skip: null })
  }

  const debounceSkipReview = useDebouncedCallback(async () => {
    await skipFeedReview(String(showModal))
      .unwrap()
      .finally(() => {
        onReload()
        handleCancel()
        onReviewSkippedEvent(String(showModal), userId)
      })
  }, DEBOUNCE_DELAY_1000)

  return (
    <div>
      <StyledModal
        open={Boolean(showModal)}
        title={t('reviewsPage.reviewTrack')}
        footer={null}
        onCancel={handleCancel}
        closeIcon={<CloseIconWhite />}
        centered
      >
        <SkipReviewModalWrapper>
          <SkipReviewModalHeader>
            {t('reviewsPage.skipReview')}
          </SkipReviewModalHeader>
          <SkipReviewModalText>
            {t('reviewsPage.ifThisTrack')}
          </SkipReviewModalText>
          <ModalButtons>
            <Button type='whiteWithGreenBorder' onClick={handleCancel}>
              {t('reviewsPage.cancel')}
            </Button>
            <Button
              type='green'
              disabled={isLoading}
              onClick={debounceSkipReview}
            >
              {t('reviewsPage.confirm')}
            </Button>
          </ModalButtons>
        </SkipReviewModalWrapper>
      </StyledModal>
    </div>
  )
}

export default SkipReviewModal
