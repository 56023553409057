import { FC } from 'react'

import { Tabs } from 'antd'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'

import { TabKeys } from 'src/Containers/Tracks/helpers'
import { colors, device } from 'src/Styled/variables'
import { sounds } from 'src/Router/routes'
import { RoundedButton } from 'src/Components/Buttons/RoundButton'
import { useNewSoundsQuery } from 'src/Redux/creatorNewSoundsApi'
import { useInProgressSoundsQuery } from 'src/Redux/creatorInProgressSoundsApi'
import { useSubmittedSoundsQuery } from 'src/Redux/creatorSubmittedSoundsApi'
import { useAppSelector } from 'src/Hooks/redux'
import { getInfluencerId } from 'src/Redux/auth-process/userSlice/selectors'

import { TabKeysEnum } from '../../types'

import { useTracksTable } from './useTracksTable'

export const SoundsTableContainer = styled.div`
  display: flex;
  max-width: 1120px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 4px;
  background-color: ${colors.white};
  position: relative;
`

export const StyledTabs = styled(Tabs)`
  width: 100%;

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${colors.mainText} !important;
  }

  .ant-tabs-tab {
    padding: 0;
    color: ${colors.greyTextWhiteBg} !important;
  }

  .ant-tabs-ink-bar {
    background: ${colors.green};
    bottom: -2px !important;
    border-radius: 1px;
  }

  .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs-nav-list {
    border-bottom: 2px solid ${colors.greyStroke};
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-nav {
    margin-bottom: 24px;
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }
`

export const RefreshButtonWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;

  @media ${device.mobileMax} {
    right: 0;
    top: -56px;
  }
`

export const TracksTable: FC = () => {
  const { activeTab } = useParams<{
    activeTab: TabKeys
  }>()
  const influencerId = useAppSelector(getInfluencerId)

  const { refetch: refetchNewSounds } = useNewSoundsQuery({
    creatorId: influencerId,
    pageNumber: 1,
  })

  const { refetch: refetchInProgressSounds } = useInProgressSoundsQuery(1)

  const { refetch: refetchSubmittedSounds } = useSubmittedSoundsQuery(1)

  const navigate = useNavigate()

  const { tabs } = useTracksTable()
  const defaultTab = activeTab || TabKeysEnum.NEW

  const onTabChange = (key: string): void => {
    navigate(`${sounds}/${key}`)
  }
  const onRefreshClick = (): void => {
    void refetchNewSounds()
    void refetchInProgressSounds()
    void refetchSubmittedSounds()
  }

  return (
    <SoundsTableContainer>
      <StyledTabs
        items={tabs}
        defaultActiveKey={defaultTab}
        onChange={onTabChange}
      />
      <RefreshButtonWrapper>
        <RoundedButton onClick={onRefreshClick} />
      </RefreshButtonWrapper>
    </SoundsTableContainer>
  )
}
