import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import ArrowIconGreen from 'src/Assets/Svg/arrow-green.svg?react'
import { FeedFilterType, FeedFilterDirection } from 'src/Types'

import { FilterContentContainer, FilterContentItem, Icon } from './styles'

interface ListContentProps {
  setFilter: (value: FeedFilterType) => void
  filterType: FeedFilterType
  filterDirection: FeedFilterDirection
}
export const ListContent: FC<ListContentProps> = ({
  setFilter,
  filterDirection,
  filterType,
}) => {
  const { t } = useTranslation()
  return (
    <FilterContentContainer>
      <FilterContentItem onClick={() => setFilter(FeedFilterType.date)}>
        {t('tracksFeedPage.byDate')}
        <Icon
          active={filterType === FeedFilterType.date}
          up={filterDirection === FeedFilterDirection.ASC}
        >
          <ArrowIconGreen />
        </Icon>
      </FilterContentItem>
      <FilterContentItem onClick={() => setFilter(FeedFilterType.genres)}>
        {t('tracksFeedPage.byGenres')}
        <Icon
          active={filterType === FeedFilterType.genres}
          up={filterDirection === FeedFilterDirection.ASC}
        >
          <ArrowIconGreen />
        </Icon>
      </FilterContentItem>
      <FilterContentItem onClick={() => setFilter(FeedFilterType.playlists)}>
        {t('tracksFeedPage.byPlaylists')}
        <Icon
          active={filterType === FeedFilterType.playlists}
          up={filterDirection === FeedFilterDirection.ASC}
        >
          <ArrowIconGreen />
        </Icon>
      </FilterContentItem>
    </FilterContentContainer>
  )
}
