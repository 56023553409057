import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { ListLimitMessage } from '../styles'
import { EmptyTrackList } from '../../EmptyTrackList'

interface Props {
  hasTracks: boolean
}

export const BottomMessage: FC<Props> = ({ hasTracks }) => {
  const { t } = useTranslation()

  if (hasTracks) {
    return (
      <ListLimitMessage>{t('reviewsPage.yourListOfTracks')}</ListLimitMessage>
    )
  }
  return <EmptyTrackList>{t('reviewsPage.emptyAwaitingList')}</EmptyTrackList>
}
