import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const ErrorText = styled.div`
  text-align: center;
  margin-bottom: 8px;
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
`

export const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
export const ErrorSubText = styled.div`
  text-align: center;
  color: ${colors.mainText};
`
export const ErrorLink = styled.a`
  color: ${colors.green};
  border-bottom: 1px dashed ${colors.green};
`
