import { FunctionComponent } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'
import { useMediaQuery } from 'react-responsive'

import { useAppDispatch } from 'src/Hooks/redux'
import { changeMobileSidebarVisibility } from 'src/Redux/sidebar-process'
import { getReviewedTracksSelector } from 'src/Redux/reviews-process/selectors'
import SidebarArtistSurvey from 'src/Components/SideBarSurveys/SidebarArtistSurvey'
import SidebarCuratorSurvey from 'src/Components/SideBarSurveys/SidebarCuratorSurvey'
import IconLiveChat from 'src/Assets/Svg/liveChat.svg?react'
import {
  getIsArtist,
  getIsCurator,
} from 'src/Redux/auth-process/credentialsSlice/selectors'
import {
  getIsBlockedCurator,
  getIsDeactivatedCurator,
  getUserSelector,
} from 'src/Redux/auth-process/userSlice/selectors'
import { getCampaigns } from 'src/Redux/campaignList-process'

import { SoundLink } from './SoundLink'
import { ProductLiftRequestFeature } from './ProductLiftRequestFeature'

import {
  SidebarLinkItem,
  SidebarIconBlock,
  SidebarLinkText,
  Bottom,
  BottomInner,
} from './styles'

const MIN_REVIEWED = 5

interface SidebarBottomProps {
  collapse: boolean
}

const SideBarBottom: FunctionComponent<SidebarBottomProps> = ({ collapse }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { show } = useIntercom()
  const user = useSelector(getUserSelector)
  const isArtist = useSelector(getIsArtist)
  const isCurator = useSelector(getIsCurator)
  const campaigns = useSelector(getCampaigns)
  const reviewedCampaigns = useSelector(getReviewedTracksSelector)
  const reviewsMade = reviewedCampaigns?.length.toString()
  const isEnoughReviewsAmount = Boolean(
    reviewedCampaigns.length >= MIN_REVIEWED,
  )
  const isDeactivated = useSelector(getIsDeactivatedCurator)
  const isBlocked = useSelector(getIsBlockedCurator)

  const isAppropriateArtistStatus = Boolean(
    campaigns.find(
      ({ status }) =>
        status === 'ended' || status === 'scheduled' || status === 'running',
    ),
  )

  const isFeedExist = Boolean(campaigns.find(({ is_feed }) => is_feed))

  const isShowSurvey =
    isCurator && isEnoughReviewsAmount && !isDeactivated && !isBlocked

  const isTablet = useMediaQuery({ maxWidth: 1023 })

  const onChangeMobileSidebarVisibility = (): void => {
    show()
    isTablet && dispatch(changeMobileSidebarVisibility())
  }

  return (
    <Bottom collapse={collapse}>
      <SoundLink />

      <BottomInner>
        <ProductLiftRequestFeature collapse={!collapse} />
        <SidebarLinkItem
          collapse={collapse}
          onClick={onChangeMobileSidebarVisibility}
        >
          <SidebarIconBlock>
            <IconLiveChat width={16} height={16} />
          </SidebarIconBlock>
          <SidebarLinkText collapse={!collapse}>
            {t('sidebar.liveChat')}
          </SidebarLinkText>
        </SidebarLinkItem>
      </BottomInner>

      {/* Typeform at least one campaign exists with the status either ended or scheduled or running or at least one campaign has is_feed flag */}
      {isArtist && (isAppropriateArtistStatus || isFeedExist) ? (
        <SidebarArtistSurvey
          collapse={collapse}
          userEmail={user?.email}
          isFeedExist={isFeedExist}
          id='Xh2CXR6m'
          text={t('sidebar.shareFeedback')}
        />
      ) : null}

      {/* Typeform Curator made min 5 review status is not Inactive, not Blocked, not Blacklisted */}
      {isShowSurvey && (
        <SidebarCuratorSurvey
          collapse={collapse}
          curatorEmail={user?.email}
          reviewsMade={reviewsMade}
          id='ik6NwSyH'
          text={t('sidebar.shareFeedback')}
        />
      )}
    </Bottom>
  )
}

export default SideBarBottom
