import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { ReviewStatus } from 'src/Types/index'
import Button from 'src/Components/Buttons/Button'

import { ReviewedListItemProps } from './props'

import {
  Avatar,
  AvatarContainer,
  ButtonsContainer,
  ReviewedListItemContainer,
  ContentContainer,
  MainContent,
  MobileSectionTitle,
  ProgressContainer,
  RewardContainer,
  RewardValue,
  TrackAuthor,
  TrackMainInfo,
  TrackTitle,
} from './styles'

export const ReviewedListItem: FC<ReviewedListItemProps> = ({
  review,
  openReviewModal,
}) => {
  const { t } = useTranslation()

  const {
    campaign: { id: campaignId, track_name, track_image, artist_name },
    rank,
    potential_reward,
  } = review

  return (
    <ReviewedListItemContainer>
      <ContentContainer>
        <AvatarContainer>
          <Avatar src={track_image} alt='avatar' />
        </AvatarContainer>
        <MainContent>
          <TrackMainInfo>
            <MobileSectionTitle>
              {t('campaignsPage.trackName')}
            </MobileSectionTitle>
            <TrackTitle>{track_name}</TrackTitle>
            <MobileSectionTitle>{t('campaignsPage.artist')}</MobileSectionTitle>
            <TrackAuthor>{artist_name}</TrackAuthor>
          </TrackMainInfo>
          <ProgressContainer>
            <RewardContainer>
              {t('reviewTrackDetails.reward')}
              <RewardValue
                status={ReviewStatus.reviewed}
              >{`$${potential_reward} (Rank ${rank})`}</RewardValue>
            </RewardContainer>
          </ProgressContainer>
          <ButtonsContainer>
            <Button
              type='whiteWithGreenBorder'
              onClick={() =>
                openReviewModal({
                  campaign_id: Number(campaignId),
                  isReviewed: true,
                })
              }
            >
              {t('campaignsPage.details')}
            </Button>
          </ButtonsContainer>
        </MainContent>
      </ContentContainer>
    </ReviewedListItemContainer>
  )
}
