import { useEffect } from 'react'

import { onGetFeedEvent } from 'src/Containers/TracksFeed/components/TrackList/helpers'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { PendingFeedReviewDetails } from 'src/Redux/TrackFeedDetailsApi'

import { useAppSelector } from './redux'

export const useGetFeedEvent = (
  response?: PendingFeedReviewDetails,
  id?: string,
): void => {
  const userId = useAppSelector(getUserId)
  useEffect(() => {
    if (response && id && userId) {
      onGetFeedEvent(response, Number(id), userId)
    }
  }, [userId, response, id])
}
