import React, { FunctionComponent, useCallback } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SingleValue } from 'react-select'

import { curatorSelectedPositionAddTrackEvent } from 'src/Helpers/TagManager'
import { createArrayFromLength } from 'src/Helpers/calculations'
import { POSITIONS_AMOUNT } from 'src/Constants/numeric'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'

import { SelectPlaylistProps, SelectPositionOption } from './props'

import { Select, SelectOption } from './styles'

const SelectPositionComponent: FunctionComponent<SelectPlaylistProps> = ({
  ref,
  onChange,
  value,
  amountPaid,
  trackId,
  reviewId,
  campaignId,
}) => {
  const { t } = useTranslation()

  const user = useSelector(getUserSelector)

  const reviewPlaylistsPositionsOptions = (): Array<SelectPositionOption> => {
    const positions = createArrayFromLength(POSITIONS_AMOUNT)
    return positions.map((position) => ({
      value: position,
      label: <SelectOption>{position}</SelectOption>,
    }))
  }

  const handleChange = useCallback(
    (val: SingleValue<SelectPositionOption>) => {
      curatorSelectedPositionAddTrackEvent({
        amountPaid,
        trackId,
        reviewId,
        campaignId,
        userId: user.id,
        positionInPlaylist: Number(val?.value),
      })
      onChange(val?.value || 1)
    },
    [amountPaid, campaignId, onChange, reviewId, trackId, user.id],
  )

  return (
    <Select
      ref={ref}
      fill
      isSearchable={false}
      options={reviewPlaylistsPositionsOptions()}
      placeholder={t('reviewsPage.selectPositionPlaceholder')}
      classNamePrefix='select-playlist-position'
      value={reviewPlaylistsPositionsOptions().find((c) => c.value === value)}
      onChange={handleChange}
    />
  )
}

export const SelectPosition = React.memo(SelectPositionComponent)
