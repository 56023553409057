import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { capitalize, snakeCaseTransform } from 'src/Helpers/layoutHelpers'
import { colors, variables } from 'src/Styled/variables'
import { transformArrayStrings } from 'src/Constants/functions'

const TrackDetailsTitle = styled.div`
  font-size: ${variables.fontSize10};
  color: ${colors.mainText};
`
const TrackDetailsRow = styled.div`
  display: flex;
  gap: 8px;
`
const TrackDetailsRowLabel = styled.div`
  display: inline-block;
  width: 110px;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.greyTextWhiteBg};
  flex-shrink: 0;
`
const TrackDetailsRowInfo = styled.div<{ color?: string }>`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${({ color }) => color || colors.mainText};
`

interface ReviewedTrackDetailsProps {
  genres?: string[]
  languages?: string[]
  status?: string
  reward?: number
  rank?: number
}

export const ReviewedTrackDetails: FC<ReviewedTrackDetailsProps> = ({
  genres,
  languages,
  status,
  reward,
  rank,
}) => {
  const { t } = useTranslation()
  const rankString = rank ? `(Rank ${rank})` : ''
  const detailsList = [
    {
      label: 'reviewTrackDetails.matchedGenres',
      info: transformArrayStrings(genres),
    },
    {
      label: 'reviewTrackDetails.vocal',
      info: transformArrayStrings(languages),
    },
    {
      label: 'reviewTrackDetails.status',
      info: status ? capitalize(snakeCaseTransform(status)) : '-',
    },
    {
      label: 'reviewTrackDetails.reward',
      info: `${reward ? reward : '-'} ${rankString}`,
    },
  ]
  return (
    <div>
      <TrackDetailsTitle>
        {t('reviewTrackDetails.trackDetails')}
      </TrackDetailsTitle>
      {detailsList.map((item) => (
        <TrackDetailsRow key={item.label}>
          <TrackDetailsRowLabel>{t(item.label)}</TrackDetailsRowLabel>
          <TrackDetailsRowInfo
            color={item.label.includes('status') ? colors.badgeGreen : ''}
          >
            {item.info}
          </TrackDetailsRowInfo>
        </TrackDetailsRow>
      ))}
    </div>
  )
}
