import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { SliceStatus } from 'src/Redux/types'

import { RootState } from '../../index'

import { CampaignState } from '.'

const getState = (state: RootState): CampaignState => state.campaigns.main

export const getCampaignToSchedule = createDraftSafeSelector(
  getState,
  ({ campaignToSchedule }) => campaignToSchedule?.campaign,
)
export const getCampaignToSchedulePrice = createDraftSafeSelector(
  getState,
  ({ campaignToSchedule }) => campaignToSchedule?.campaign.price,
)
export const getIsEnoughBalance = createDraftSafeSelector(
  (state: RootState) => ({
    balance: Number(state.authentication.user.user.balance),
    price: Number(state.campaigns.main.campaignToSchedule?.campaign.price),
  }),
  ({ balance, price }) => balance >= price && Boolean(price),
)

export const getSpotifyCampaignError = createDraftSafeSelector(
  getState,
  ({ error }) => error,
)

export const getSchedulePlatform = createDraftSafeSelector(
  getState,
  ({ schedulePlatform }) => schedulePlatform,
)
export const getIsUpdateCampaignLoading = createDraftSafeSelector(
  getState,
  ({ updateCampaignLoading }) =>
    updateCampaignLoading === SliceStatus.Loading ||
    updateCampaignLoading === SliceStatus.Idle,
)

export const getGenresListSelector = createDraftSafeSelector(
  getState,
  ({ campaignToSchedule }) =>
    campaignToSchedule.campaign.genres.map((genre) => genre.name).join(),
)

export const getLanguagesListSelector = createDraftSafeSelector(
  getState,
  ({ campaignToSchedule }) =>
    campaignToSchedule.campaign.languages
      .map((language) => language.name)
      .join(),
)
