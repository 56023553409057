import { FC } from 'react'

import { ModalProps } from 'antd'

import { StyledModal } from './styles'

export type Props = ModalProps & {
  showTopbar?: boolean
}

export const FullScreenModal: FC<Props> = (props) => {
  const { children, ...restProps } = props
  return <StyledModal {...restProps}>{children}</StyledModal>
}
