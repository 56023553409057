import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Exit from 'src/Assets/Svg/header-icons/exit.svg?react'
import Settings from 'src/Assets/Svg/header-icons/settingsNew.svg?react'
import { CreateCampaignButton } from 'src/Components/Buttons/CreateCampaignButton'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { FloatCreateCampaignButton } from 'src/Components/Buttons/FloatCreateCampaignButton'

import { PrimaryHeader, PrimaryHeaderProps } from '../PrimaryHeader'

const StyledCreateCampaignButton = styled(CreateCampaignButton)`
  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    display: none;
  }
`

const StyledFloatCreateCampaignButton = styled(FloatCreateCampaignButton)`
  position: absolute;
  bottom: 16px;
  right: 16px;

  @media (min-width: ${MAX_MOBILE_WIDTH}) {
    display: none;
  }
`

export type ArtistHeaderProps = Pick<
  PrimaryHeaderProps,
  | 'withShadow'
  | 'balance'
  | 'isBalanceButtonLoading'
  | 'onSidebarToggleClick'
  | 'onBalanceButtonClick'
  | 'onNotificationsButtonClick'
> & {
  onCreateCampaignButtonClick: () => void
  onSettingsClick: () => void
  onLogoutClick: () => void
  className?: string
}

export const ArtistHeader: FC<ArtistHeaderProps> = ({
  withShadow,
  balance,
  onSidebarToggleClick,
  onBalanceButtonClick,
  onSettingsClick,
  onLogoutClick,
  onNotificationsButtonClick,
  onCreateCampaignButtonClick,
  isBalanceButtonLoading,
  className,
}) => {
  const { t } = useTranslation()

  const menuItems = [
    {
      title: t('header.settings'),
      Icon: Settings,
      onClick: onSettingsClick,
    },
    {
      title: t('header.logout'),
      Icon: Exit,
      onClick: onLogoutClick,
    },
  ]

  return (
    <PrimaryHeader
      withShadow={withShadow}
      onSidebarToggleClick={onSidebarToggleClick}
      onBalanceButtonClick={onBalanceButtonClick}
      onNotificationsButtonClick={onNotificationsButtonClick}
      balance={balance}
      isBalanceButtonLoading={isBalanceButtonLoading}
      menuItems={menuItems}
      className={className}
    >
      <StyledCreateCampaignButton onClick={onCreateCampaignButtonClick} />
      <StyledFloatCreateCampaignButton onClick={onCreateCampaignButtonClick} />
    </PrimaryHeader>
  )
}
