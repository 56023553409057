import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { currencySymbolConverter } from 'src/Constants/functions'
import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'

import { CurrencyState } from '.'

export const getState = (state: RootState): CurrencyState =>
  state.authentication.currency

export const getCurrency = createDraftSafeSelector(
  getState,
  ({ currency }) => currency,
)
export const getCurrencyTiktok = createDraftSafeSelector(
  getState,
  ({ currency }) => {
    const { rate, name } = currency
    const currencySymbol = currencySymbolConverter(name)
    return {
      rate,
      currencySymbol,
    }
  },
)

export const getCurrencyError = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Failed,
)
