import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const TabButtonTitle = styled.span`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
`

export const TabButtonCount = styled.span`
  margin-left: 8px;
  font-size: ${variables.fontSize14};
  color: ${colors.greyTextBlackBg};
`
