import React from 'react'

import { InfoCardProps } from './infoCardProps'

import { InfoCardWrapper, Title, Value } from './styles'

const InfoCard: React.FC<InfoCardProps> = ({ title, value, type }) => (
  <InfoCardWrapper>
    <Title>{title}</Title>
    <Value type={type}>{value}</Value>
  </InfoCardWrapper>
)

export default InfoCard
