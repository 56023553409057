import { FunctionComponent, useMemo } from 'react'

import { useSelector } from 'react-redux'

import { getTermsPosition } from 'src/Redux/auth-process/userSlice/selectors'

import { Desktop, Mobile } from '../Responsive'

import { useSlidesContent } from './useSlidesContent'

import {
  ContentContainer,
  inlineStyles,
  MobileContainer,
  ModalContainer,
} from './styles'

const TermsAndConditions: FunctionComponent = () => {
  const { slides } = useSlidesContent()
  const termsPosition = useSelector(getTermsPosition)

  const memoCurrentSlide = useMemo(
    () => slides?.find((item) => item.id === termsPosition) || slides[0],
    [termsPosition, slides],
  )

  if (!memoCurrentSlide) {
    return null
  }

  return (
    <>
      <Desktop>
        <ModalContainer
          open={true}
          centered
          styles={{
            mask: inlineStyles.modalMask,
          }}
        >
          <ContentContainer>{memoCurrentSlide.content}</ContentContainer>
        </ModalContainer>
      </Desktop>
      <Mobile>
        <MobileContainer>
          <ContentContainer>{memoCurrentSlide.content}</ContentContainer>
        </MobileContainer>
      </Mobile>
    </>
  )
}

export default TermsAndConditions
