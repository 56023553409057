import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Image5 from 'src/Assets/Svg/image5.svg?react'

import {
  StyledButton,
  ButtonBack,
  ButtonsContainer,
  ImageWrapper,
  Paragraph,
  Text,
  TextContainer,
  Title,
} from '../styles'
import { Slides } from '../useSlidesContent'
import { Pagination } from '../Pagination/Pagination'

interface FifthStepProps {
  onNextClick: () => void
  onBackClick: () => void
  disabled?: boolean
}

export const FifthStep: FC<FifthStepProps> = ({
  onNextClick,
  onBackClick,
  disabled,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ImageWrapper>
        <Image5 data-testid={'fifth image'} />
      </ImageWrapper>
      <Title>{t('termsAndConditions.title5')}</Title>
      <TextContainer>
        <Paragraph>
          <Text monospace>{t('termsAndConditions.text5-1')}</Text>
          <Text monospace bold>
            {t('termsAndConditions.text5-2')}
          </Text>
          <Text monospace>{t('termsAndConditions.text5-3')}</Text>
        </Paragraph>
        <Paragraph>
          <Text monospace>{t('termsAndConditions.text5-4')}</Text>
          <Text monospace bold>
            {t('termsAndConditions.text5-5')}
          </Text>
        </Paragraph>
        <Paragraph>
          <Text monospace>{t('termsAndConditions.text5-6')}</Text>
          <Text monospace bold>
            {t('termsAndConditions.text5-7')}
          </Text>
        </Paragraph>
      </TextContainer>
      <Pagination activeItem={Slides.FIFTH} />
      <ButtonsContainer>
        <ButtonBack disabled={disabled} onClick={onBackClick}>
          {t('termsAndConditions.back')}
        </ButtonBack>
        <StyledButton disabled={disabled} onClick={onNextClick}>
          {t('termsAndConditions.continue')}
        </StyledButton>
      </ButtonsContainer>
    </>
  )
}
