import { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { tracksFeed } from 'src/Router/routes'

import { PendingFeedReviewDetails, useGetPendingTrackDetailsQuery } from '.'

type TractToReview = {
  data: PendingFeedReviewDetails | undefined
  isLoading: boolean
  isError: boolean
  campaignId?: string
}

export const useTrackToReview = (
  refetchOnMountOrArgChange?: boolean,
): TractToReview => {
  const navigate = useNavigate()
  const { campaignId } = useParams<{ campaignId: string }>()
  const { data, isError, isLoading } = useGetPendingTrackDetailsQuery(
    String(campaignId),
    {
      refetchOnMountOrArgChange,
    },
  )

  useEffect(() => {
    if (isError || data?.review.reviewStatus === 'reviewed') {
      navigate(tracksFeed)
    }
  }, [isError, data?.review.reviewStatus, navigate])
  return { data, isError, isLoading, campaignId }
}
