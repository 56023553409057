import { ClipboardEvent, FC, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { colors } from 'src/Styled/variables'
import { ReviewType } from 'src/Types'
import { TextArea } from 'src/Containers/ui/TextArea'
import WarningSvg from 'src/Assets/Svg/warning.svg?react'
import { DotedButton } from 'src/Components/Buttons/DotedButton'
import Button from 'src/Components/Buttons/Button'
import { ApiError, ErrorCode } from 'src/Redux/types'
import { useGetWordsBlackListQuery } from 'src/Redux/wordBlackListApi'

import { WarningForm } from './WarningForm'
import { ReviewFormValues, reviewTrackSchema } from './helpers'
import { SelectPosition } from './SelectPosition'
import { SelectPlaylistOrReject } from './SelectPlaylistOrReject'

import {
  Form,
  SectionTitle,
  HiddenContentWrapper,
  Warning,
  WarningText,
  ButtonsContainer,
} from './styles'

interface TrackToReviewFormProps {
  reward?: number
  isLoading: boolean
  trackId?: number
  id?: string
  showWarningForm: boolean
  onSkip: () => void
  onSendReview: (data: ReviewFormValues) => Promise<void>
}

export const TrackToReviewForm: FC<TrackToReviewFormProps> = (props) => {
  const {
    showWarningForm,
    reward,
    trackId,
    isLoading,
    id,
    onSkip,
    onSendReview,
  } = props
  const { data: blacklistWords } = useGetWordsBlackListQuery()
  const { t } = useTranslation()
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ReviewFormValues>({
    mode: 'all',
    resolver: yupResolver(reviewTrackSchema(blacklistWords || [])),
  })
  const watchSelect = watch('reviewPlaylistSelect')

  useEffect(() => {
    setValue('showWarningForm', showWarningForm)
  }, [showWarningForm, setValue])

  const watchWarningForm = watch('showWarningForm')

  const handler = (event: ClipboardEvent<HTMLTextAreaElement>): void => {
    clearErrors('reviewText')
    event.preventDefault()
    setError('root', {
      type: 'root',
      message: t('reviewsPage.uniqueReviewRequired') || '',
    })
  }
  const onCommentChange = (): void => {
    setError('root', {})
  }
  const errorMessage =
    errors?.reviewText?.message ||
    errors?.root?.message ||
    errors.reviewRecommendations?.message

  const onSubmit = async (data: ReviewFormValues): Promise<void> => {
    await onSendReview(data)
      .then((result) => {
        setError('root', {})
        return result
      })
      .catch((error: ApiError) => {
        if (error.code === ErrorCode.Forbidden) {
          setError('root', {
            type: 'root',
            message: t('reviewsPage.unauthorizedMessage') || '',
          })
        }
      })
  }
  if (watchWarningForm) {
    return (
      <WarningForm
        setPlaylistFromWarning={(value) =>
          setValue('reviewPlaylistSelect', value)
        }
        setWarningFormVisible={(value) => setValue('showWarningForm', value)}
        setPositionFromWarning={(value) =>
          setValue('reviewPlaylistPositionSelect', value)
        }
      />
    )
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <SectionTitle>{t('reviewsPage.addToPlaylist')}</SectionTitle>
      <Controller
        control={control}
        name='reviewPlaylistSelect'
        render={({ field: { onChange, value } }) => (
          <SelectPlaylistOrReject value={value} onChange={onChange} />
        )}
      />
      {watchSelect?.type === ReviewType.add && (
        <Controller
          control={control}
          name='reviewPlaylistPositionSelect'
          render={({ field: { onChange, value } }) => (
            <SelectPosition
              value={value as number}
              amountPaid={Number(reward)}
              trackId={Number(trackId)}
              reviewId={Number(id)}
              campaignId={Number(id)}
              onChange={onChange}
            />
          )}
        />
      )}
      <SectionTitle>{t('reviewsPage.writeReview')}</SectionTitle>
      <Controller
        control={control}
        name='reviewText'
        render={({ field }) => (
          <TextArea
            {...field}
            $isError={errors?.reviewText?.type === 'blacklist'}
            showCount={{
              formatter: (count) => (count.value ? `${count.count}/700` : ''),
            }}
            maxLength={700}
            minLength={70}
            rows={4}
            onCopy={handler}
            onCut={handler}
            onPaste={handler}
            style={{ resize: 'none' }}
            placeholder={t('reviewsPage.commentPlaceholder') || ''}
            onChange={(e) => {
              field.onChange(e)
              onCommentChange()
            }}
          />
        )}
      />
      <HiddenContentWrapper active={watchSelect?.type === ReviewType.reject}>
        <Controller
          control={control}
          name='reviewRecommendations'
          render={({ field }) => (
            <TextArea
              {...field}
              $marginTop
              showCount={{
                formatter: (count) => (count.value ? `${count.count}/700` : ''),
              }}
              maxLength={700}
              style={{ resize: 'none' }}
              onCopy={handler}
              rows={4}
              onCut={handler}
              placeholder={t('reviewsPage.recommendationsPlaceholder') || ''}
              onPaste={handler}
              onChange={(e) => {
                field.onChange(e)
                onCommentChange()
              }}
            />
          )}
        />
      </HiddenContentWrapper>
      {errorMessage && (
        <Warning>
          <WarningSvg width={'16px'} height={'14px'} />
          <WarningText>{errorMessage}</WarningText>
        </Warning>
      )}
      <ButtonsContainer>
        <DotedButton color={colors.mainText} onClick={onSkip}>
          {t('reviewsPage.skipReview')}
        </DotedButton>
        <Button
          loading={isLoading}
          disabled={!isValid || isSubmitting}
          type='green'
          htmlType='submit'
        >
          {t('reviewsPage.sendReview')}
        </Button>
      </ButtonsContainer>
    </Form>
  )
}
