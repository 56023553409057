import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from 'src/Styled/variables'

import { PrimaryHeaderProps } from '../Header/Components/PrimaryHeader'
import { SidebarProps } from '../Sidebar'

const Container = styled.div`
  display: grid;
  grid-template-areas:
    'sidebar header'
    'sidebar content';
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  height: 100vh;
`

const Content = styled.div`
  grid-area: content;
  height: 100%;
  overflow: scroll;
  padding: 24px 40px 0;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: ${MAX_TABLET_WIDTH}) {
    padding: 24px 24px 0;
  }

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: 24px 16px 0;
  }
`

export type PageLayoutProps = PropsWithChildren & {
  Header: FC<Pick<PrimaryHeaderProps, 'withShadow' | 'onSidebarToggleClick'>>
  Sidebar: FC<Pick<SidebarProps, 'collapsed' | 'onLogoClick'>>
  isHeaderHidden?: boolean
  onSidebarToggleClick: () => void
  isSidebarCollapsed: boolean
}

const HeaderWrapper = styled.div`
  grid-area: header;
  z-index: 1;
`

const SidebarWrapper = styled.div`
  grid-area: sidebar;
  z-index: 2;
`

export const PageLayout: FC<PageLayoutProps> = ({
  children,
  Header,
  Sidebar,
  isHeaderHidden,
  onSidebarToggleClick,
  isSidebarCollapsed,
}) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [isContentScrolled, setIsContentScrolled] = useState(false)

  useEffect(() => {
    const currentContent = contentRef.current

    const handleScroll = (): void => {
      currentContent && setIsContentScrolled(currentContent.scrollTop > 0)
    }
    contentRef.current?.addEventListener('scroll', handleScroll)
    return () => {
      currentContent &&
        currentContent.removeEventListener('scroll', handleScroll)
    }
  }, [children])

  return (
    <Container>
      {!isHeaderHidden && (
        <HeaderWrapper>
          <Header
            withShadow={isContentScrolled}
            onSidebarToggleClick={onSidebarToggleClick}
          />
        </HeaderWrapper>
      )}

      <SidebarWrapper>
        <Sidebar
          collapsed={isSidebarCollapsed}
          onLogoClick={onSidebarToggleClick}
        />
      </SidebarWrapper>
      <Content ref={contentRef}>{children}</Content>
    </Container>
  )
}
