import { FC, useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { APP } from 'src/Configs/App'
import { activeTabStorage } from 'src/Constants/constants'
import { getSpotifyToken } from 'src/Redux/spotifyToken-process/selectors'
import { campaignsList, createCampaign } from 'src/Router/routes'
import { Desktop } from 'src/Components/Responsive'
import {
  CampaignsFilters,
  SpotifyCampaign,
  TiktokCampaign,
} from 'src/Types/index'
import { getIsAuth } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { TwoCreateButtonsExperiment } from 'src/Hooks/Experiments/TwoCreateButtonsExperiment'
import {
  getCampaigns,
  getSpotifyCampaigns,
  getTiktokCampaigns,
} from 'src/Redux/campaignList-process'

import ActionsBlock from '../ActionsBlock'
import { SubscriptionAlert } from '../CampaignsList/SubscriptionAlert/index'
import { EmailBouncedAlert } from '../EmailBouncedAlert'
import { EmailNotConfirmedAlert } from '../EmailNotConfirmedAlert'

import { useCampaignsTabs } from './useCampaingsTabs'
import { ApprovedAlerts } from './ApprovedAlerts/index'
import { CampaignsListTableAction } from './CampaignsListTableAction'

import { Card, StyledTabs, StyledTabsWrapper } from './styles'

const NO_ITEM_INDEX = -1

export const CampaignsListTable: FC = () => {
  const navigate = useNavigate()

  const spotifyAccessToken = useSelector(getSpotifyToken)
  const campaigns = useSelector(getCampaigns)
  const spotifyCampaigns = useSelector(getSpotifyCampaigns)
  const tiktokCampaigns = useSelector(getTiktokCampaigns)
  const auth = useSelector(getIsAuth)

  const [allCampaigns, updateAllCampaigns] = useState<
    (SpotifyCampaign | TiktokCampaign)[]
  >([])
  const [activeSearch, setActiveSearch] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const [filters, setFilters] = useState<CampaignsFilters>({
    isSpotify: false,
    isTikTok: false,
  })
  const [search, setSearch] = useState('')
  const filterCampaigns = (
    campaignsToFilter: (SpotifyCampaign | TiktokCampaign)[],
    searchString: string,
  ): (SpotifyCampaign | TiktokCampaign)[] =>
    campaignsToFilter.filter(
      (i) =>
        i.trackTitle?.toLowerCase().indexOf(searchString) !== NO_ITEM_INDEX ||
        i.trackAuthorName?.toLowerCase().indexOf(searchString) !==
          NO_ITEM_INDEX,
    )

  const handleFindCampaign = (value: string): void => {
    const searchString = value.toLowerCase()
    setSearch(searchString)
    if (filters.isSpotify === filters.isTikTok) {
      updateAllCampaigns(filterCampaigns(campaigns, searchString))
    }
    if (!filters.isSpotify && filters.isTikTok) {
      updateAllCampaigns(filterCampaigns(tiktokCampaigns, searchString))
    }
    if (filters.isSpotify && !filters.isTikTok) {
      updateAllCampaigns(filterCampaigns(spotifyCampaigns, searchString))
    }
  }

  const handleOpenCreateCampaign = (): void => {
    if (auth || spotifyAccessToken) {
      navigate(`${campaignsList}${createCampaign}`)
    } else {
      window.location.href = APP.SPOTIFY_AUTH_API
    }
  }

  useEffect(() => {
    const prevActiveTab = window.sessionStorage.getItem(activeTabStorage)
    prevActiveTab && setActiveTab(prevActiveTab)
  }, [])

  useEffect(() => {
    if (filters.isSpotify === filters.isTikTok) {
      updateAllCampaigns(filterCampaigns(campaigns, search))
    }
    if (!filters.isSpotify && filters.isTikTok) {
      updateAllCampaigns(filterCampaigns(tiktokCampaigns, search))
    }
    if (filters.isSpotify && !filters.isTikTok) {
      updateAllCampaigns(filterCampaigns(spotifyCampaigns, search))
    }
  }, [campaigns, spotifyCampaigns, tiktokCampaigns, filters, search]) // HERE 2

  const actionBlock = (
    <TwoCreateButtonsExperiment>
      <ActionsBlock
        checkAccessToken={handleOpenCreateCampaign}
        activeSearch={activeSearch}
        filters={filters}
        setFilters={setFilters}
        setActiveSearch={setActiveSearch}
        handleFindCampaign={handleFindCampaign}
      />
      <div />
    </TwoCreateButtonsExperiment>
  )

  const items = useCampaignsTabs(
    allCampaigns,
    activeTab,
    actionBlock,
    filters,
    search,
  )

  return (
    <>
      <SubscriptionAlert />
      <ApprovedAlerts activeTab={activeTab} />

      <EmailBouncedAlert />
      <EmailNotConfirmedAlert />

      <Card>
        <TwoCreateButtonsExperiment>
          <div />
          <CampaignsListTableAction
            search={search}
            setSearch={handleFindCampaign}
            filters={filters}
            setFilters={setFilters}
          />
        </TwoCreateButtonsExperiment>
        <Desktop>{actionBlock}</Desktop>
        <StyledTabsWrapper>
          <StyledTabs
            defaultActiveKey='1'
            activeKey={activeTab}
            items={items}
            onChange={(value) => setActiveTab(value)}
          />
        </StyledTabsWrapper>
      </Card>
    </>
  )
}
