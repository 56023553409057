import { FC, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { FullScreenModal } from 'src/Components/FullScreenModal'
import { useAppSelector } from 'src/Hooks/redux'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { ANY_LANGUAGE_ID } from 'src/Constants/constants'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { ModalTitle } from '../ModalTitle'
import { ListWrapper } from '../styles'
import { convertLanguages } from '../helpers'

import { LanguagesModalFooter } from './LanguagesModalFooter'
import { LanguagesList } from './LanguagesList'
export interface SelectedLanguage {
  value: number
  label: string
}

export const LanguagesModal: FC = () => {
  const { t } = useTranslation()
  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const [value, setValue] = useState<string>('')
  const [targetLanguage, setTargetLanguage] = useState<number>()
  const [selectedLanguage, setSelectedLanguage] =
    useState<SelectedLanguage | null>(null)
  const { setSearch } = useCustomSearchParams()

  const handleClose = (): void => {
    setSearch({ modal: null })
  }
  useEffect(() => {
    if (campaignDetails?.languages.length) {
      setSelectedLanguage(convertLanguages(campaignDetails?.languages))
      const anyLanguage = campaignDetails?.languages.find(
        (item) => item.id === ANY_LANGUAGE_ID,
      )?.id as number
      setTargetLanguage(anyLanguage || 0)
    } else {
      setTargetLanguage(ANY_LANGUAGE_ID)
    }
  }, [campaignDetails?.languages, campaignDetails?.languages.length])

  const handleClick = (item: SelectedLanguage): void => {
    if (selectedLanguage?.value === item.value) {
      setSelectedLanguage(null)
      return
    }
    setSelectedLanguage(item)
  }

  return (
    <FullScreenModal onCancel={handleClose} prefixCls='languages' open>
      <ModalTitle
        title={t('scheduleCampaignPage.vocalLanguage')}
        placeholder={t('scheduleCampaignPage.enterLanguageName')}
        setValue={setValue}
      />
      <ListWrapper>
        <LanguagesList
          searchValue={value}
          selectedLanguage={selectedLanguage}
          targetLanguage={targetLanguage}
          onClick={handleClick}
          onChange={(id: number) => setTargetLanguage(id)}
        />
      </ListWrapper>
      <LanguagesModalFooter
        targetLanguage={targetLanguage}
        selectedLanguage={selectedLanguage}
      />
    </FullScreenModal>
  )
}
