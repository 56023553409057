import {
  reviewRequestSeenEvent,
  reviewSkippedEvent,
} from 'src/Helpers/TagManager'
import { ID, CampaignPlatformTypeEnum } from 'src/Types/index'
import { PendingFeedReviewDetails } from 'src/Redux/TrackFeedDetailsApi'

export const onReviewSkippedEvent = (
  campaignId: string,
  userId?: ID | null,
): void => {
  if (campaignId && userId) {
    reviewSkippedEvent(
      campaignId,
      new Date().getTime(),
      CampaignPlatformTypeEnum.SPOTIFY,
      0,
      String(userId),
      true,
    )
  }
}

export const onGetFeedEvent = (
  response: PendingFeedReviewDetails,
  campaignID: number,
  userId: ID,
): void => {
  reviewRequestSeenEvent({
    campaignID,
    userId: String(userId),
    requestId: new Date().getTime(),
    platformName: CampaignPlatformTypeEnum.SPOTIFY,
    is_feed: true,
    reviewRequestType: response.review.reviewStatus,
  })
}
