import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import InfinityScrollWrapper from 'src/Components/InfinityScrollWrapper'
import { getReviewedFeedTracks } from 'src/Redux/feed-process/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'
import {
  getReviewedCurrentPageSelector,
  getReviewedTracksSelector,
  getTotalReviewedTracksSelector,
} from 'src/Redux/feed-process/selectors'

import { ReviewedListItem } from '../../ReviewedListItem'
import { TrackFeedReviewedReview } from '../../ReviewedListItem/props'
import { ReviewMobileProps } from '../props'
import { EmptyTrackList } from '../../EmptyTrackList'

interface Props {
  openReviewModal: (props: ReviewMobileProps) => void
}
export const ReviewedCampaignsList: FC<Props> = (props) => {
  const dispatch = useAppDispatch()
  const { openReviewModal } = props
  const totalReviewed = useSelector(getTotalReviewedTracksSelector)
  const currentPage = useSelector(getReviewedCurrentPageSelector)
  const reviewedCampaigns = useSelector(getReviewedTracksSelector)
  const { t } = useTranslation()
  const [isReviewedLoading, setIsReviewedLoading] = useState<boolean>(false)

  const getCurrentReviewedTracks = (): void => {
    setIsReviewedLoading(true)
    void dispatch(
      getReviewedFeedTracks({
        page: currentPage + 1,
      }),
    )
    setIsReviewedLoading(false)
  }

  if (!reviewedCampaigns.length) {
    return <EmptyTrackList>{t('reviewsPage.emptyReviewedList')}</EmptyTrackList>
  }

  return (
    <InfinityScrollWrapper
      dataLength={reviewedCampaigns.length}
      isLoading={isReviewedLoading}
      next={getCurrentReviewedTracks}
      hasMore={totalReviewed > reviewedCampaigns.length}
    >
      {reviewedCampaigns.map((item) => (
        <ReviewedListItem
          key={item.campaign.id}
          review={item as unknown as TrackFeedReviewedReview}
          openReviewModal={openReviewModal}
        />
      ))}
    </InfinityScrollWrapper>
  )
}
