import { createSlice } from '@reduxjs/toolkit'

import { NameSpace, SliceStatus } from 'src/Redux/types'
import { Currency } from 'src/Types'

import { getCurrencyInfo } from './api-actions'

export interface CurrencyState {
  currency: Currency
  status: SliceStatus
}

const initialState: CurrencyState = {
  currency: { name: 'USD', rate: 1 },
  status: SliceStatus.Idle,
}

export const currencySlice = createSlice({
  initialState,
  name: NameSpace.Currency,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCurrencyInfo.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getCurrencyInfo.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.currency = {
        name: payload.currency_rate > 0 ? payload.currency_name : 'USD',
        rate: payload.currency_rate > 0 ? payload.currency_rate : 1,
      }
    })
    builder.addCase(getCurrencyInfo.rejected, (state) => {
      state.status = SliceStatus.Failed
    })
  },
})
