import React, { FC } from 'react'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

type Props = {
  children: [React.ReactElement, React.ReactElement]
}

export const TwoCreateButtonsExperiment: FC<Props> = ({ children }) => {
  const isVariantB = useFeatureIsOn('two_create_buttons')

  if (isVariantB) {
    return <div>{children[1]}</div>
  }
  return <div>{children[0]}</div>
}
