import { FunctionComponent } from 'react'

import { useSelector } from 'react-redux'

import { getFormattedCheckoutTimer } from 'src/Redux/checkoutCountdown-process'

import { Container, Arrow, Position, Countdown } from './styles'

export interface TitleProps {
  description: string
  position: Position
}

export const BonusTooltip: FunctionComponent<TitleProps> = ({
  description,
  position,
}) => {
  const countdown = useSelector(getFormattedCheckoutTimer)

  return (
    <Container>
      <Countdown>{countdown}</Countdown>
      <span>{description}</span>
      <Arrow position={position} />
    </Container>
  )
}
