import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import {
  PlaylistOrRejectOption,
  useSelectPlaylistOrRejectOptions,
} from './useSelectPlaylistOrRejectOptions'

import { StyledSelect, StyledSelectContainer } from './styles'

interface PlaylistValue {
  id: number
  type: string
}
interface SelectPlaylistProps {
  onChange: (props: { id: number; type: string }) => void
  value?: PlaylistValue
  noReject?: boolean
}

export const SelectPlaylistOrReject: FC<SelectPlaylistProps> = (props) => {
  const { onChange, value, noReject } = props

  const isMobile = useMediaQuery({ maxWidth: 576 })
  const { t } = useTranslation()
  const options = useSelectPlaylistOrRejectOptions(noReject)

  const handleChange = (
    currentValue: number,
    option: PlaylistOrRejectOption | PlaylistOrRejectOption[],
  ): void => {
    if (option instanceof Array) {
      return
    }
    onChange({ id: currentValue, type: option.type })
  }
  const placeholder = t('reviewsPage.selectPlaceholder')

  const getPopupContainer = (
    target: globalThis.HTMLElement,
  ): globalThis.HTMLElement => {
    if (isMobile || !target.parentElement) {
      return document.body
    }
    return target.parentElement
  }
  return (
    <StyledSelectContainer>
      <StyledSelect
        options={options}
        value={value?.id}
        onChange={handleChange}
        placeholder={placeholder}
        getPopupContainer={getPopupContainer}
      />
    </StyledSelectContainer>
  )
}
