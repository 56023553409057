import { ReactElement } from 'react'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { acceptTermsNew } from 'src/Redux/auth-process/userSlice/api-actions'
import { getIsUserLoading } from 'src/Redux/auth-process/userSlice/selectors'

import { FirthStep } from './FirthStep/FirthStep'
import { SecondStep } from './SecondStep/SecondStep'
import { ThirdStep } from './ThirdStep/ThirdStep'
import { FourthStep } from './FourthStep/FourthStep'
import { FifthStep } from './FifthStep/FifthStep'
import { SixthStep } from './SixthStep/SixthStep'
import { FinalStep } from './FinalStep/FinalStep'

interface ISlide {
  id: number
  content: ReactElement
}

export enum Slides {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
  FIFTH = 5,
  SIXTH = 6,
  SEVENTH = 7,
}

export const useSlidesContent = (): {
  slides: Array<ISlide>
} => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(getIsUserLoading)

  const goToNextSlide = (position: number): void => {
    void dispatch(
      acceptTermsNew({
        termsPosition: position + 1,
        termsCount: 8,
      }),
    )
      .unwrap()
      .then((result) => {
        window.scrollTo(0, 0)
        return result
      })
  }

  const goToPrevSlide = (position: number): void => {
    void dispatch(
      acceptTermsNew({
        termsPosition: position - 1,
        termsCount: 8,
      }),
    )
      .unwrap()
      .then((result) => {
        window.scrollTo(0, 0)
        return result
      })
  }

  const slides: ISlide[] = [
    {
      id: Slides.FIRST,
      content: (
        <FirthStep
          disabled={loading}
          onClick={() => goToNextSlide(Slides.FIRST)}
        />
      ),
    },
    {
      id: Slides.SECOND,
      content: (
        <SecondStep
          disabled={loading}
          onBackClick={() => goToPrevSlide(Slides.SECOND)}
          onNextClick={() => goToNextSlide(Slides.SECOND)}
        />
      ),
    },
    {
      id: Slides.THIRD,
      content: (
        <ThirdStep
          disabled={loading}
          onBackClick={() => goToPrevSlide(Slides.THIRD)}
          onNextClick={() => goToNextSlide(Slides.THIRD)}
        />
      ),
    },
    {
      id: Slides.FOURTH,
      content: (
        <FourthStep
          disabled={loading}
          onBackClick={() => goToPrevSlide(Slides.FOURTH)}
          onNextClick={() => goToNextSlide(Slides.FOURTH)}
        />
      ),
    },
    {
      id: Slides.FIFTH,
      content: (
        <FifthStep
          disabled={loading}
          onBackClick={() => goToPrevSlide(Slides.FIFTH)}
          onNextClick={() => goToNextSlide(Slides.FIFTH)}
        />
      ),
    },
    {
      id: Slides.SIXTH,
      content: (
        <SixthStep
          disabled={loading}
          onBackClick={() => goToPrevSlide(Slides.SIXTH)}
          onNextClick={() => goToNextSlide(Slides.SIXTH)}
        />
      ),
    },
    {
      id: Slides.SEVENTH,
      content: (
        <FinalStep
          disabled={loading}
          onFinalClick={() => goToNextSlide(Slides.SEVENTH)}
        />
      ),
    },
  ]
  return {
    slides,
  }
}
