import { AnyObject, ObjectSchema, boolean, number, object, string } from 'yup'

import { REVIEW_TEXT_MIN_LENGTH } from 'src/Constants/numeric'
import i18n from 'src/Localization/i18n'
import { ReviewType } from 'src/Types'

export interface ReviewFormValues {
  reviewPlaylistSelect: { id: number; type: string }

  reviewPlaylistPositionSelect?: number

  reviewRecommendations?: string
  reviewText?: string
  showWarningForm: boolean
}

const validateBlacklistedWords = (
  blacklistWords: Array<string>,
  value?: string,
): boolean => {
  if (!value) {
    return true
  }
  const userWords = value?.toLowerCase()
  return !blacklistWords?.some((text) => {
    const updatedText = text.toLowerCase().trim()
    const regex = new RegExp(
      `\\b${updatedText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\b`,
      'gi',
    )
    return regex.test(userWords)
  })
}

const commentTooShortText = i18n.t('reviewsPage.commentTooShort')

const blacklistErrorText = i18n.t('reviewsPage.blacklistError')

export const reviewTrackSchema = (
  blacklistWords: Array<string>,
): ObjectSchema<ReviewFormValues, AnyObject, unknown> =>
  object().shape({
    reviewText: string()
      .required(commentTooShortText)
      .test(
        'len',
        commentTooShortText,
        (value) => value?.replace(/\s/g, '').length > REVIEW_TEXT_MIN_LENGTH,
      )
      .test('blacklist', blacklistErrorText, (value) =>
        validateBlacklistedWords(blacklistWords, value),
      ),
    reviewRecommendations: string().test(
      'blacklist',
      blacklistErrorText,
      (value) => validateBlacklistedWords(blacklistWords, value),
    ),
    reviewPlaylistSelect: object().shape({
      id: number().required(),
      type: string().required(),
    }),
    reviewPlaylistPositionSelect: number().when('reviewPlaylistSelect.type', {
      is: (value: ReviewType) => value === ReviewType.add,
      then: (schema) => schema.required('Please select a position'),
    }),
    showWarningForm: boolean().required(),
  })
