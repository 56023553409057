import { FC, useCallback, useEffect, useRef, useState } from 'react'

export type OnFullTrackListenedBody = PlaybackData & { currentTrackUrl: string }

type SpotifyPreviewTrackPlayer = {
  onFullTrackListened?: (data: OnFullTrackListenedBody) => void
  render: (props: {
    onClick: (e: React.MouseEvent<HTMLDivElement>, trackUrl?: string) => void
    loading: boolean
    playbackData: PlaybackData | null
    currentTrackUrl?: string
    isPlaying: boolean
  }) => JSX.Element
}

interface PlaybackData {
  duration: number
  isBuffering: boolean
  isPaused: boolean
  position: number
}

interface IEmbedController {
  onPlaybackUpdate: (data: PlaybackData) => void
  addListener: (event: string, callback: (e: unknown) => void) => void
  loadUri: (uri: string) => void
  play: () => void
  pause: () => void
  resume: () => void
  destroy: () => void
}
interface IFrameAPI {
  createController: (
    element: HTMLElement,
    options: { height: number; uri?: string },
    callback: (EmbedController: IEmbedController) => void,
  ) => void
}

type WindowType = typeof window & {
  onSpotifyIframeApiReady: (API: IFrameAPI) => void | undefined
}

export const SpotifyPreviewTrackPlayer: FC<SpotifyPreviewTrackPlayer> = ({
  render,
  onFullTrackListened,
}) => {
  const [playbackData, setPlaybackData] = useState<PlaybackData | null>(null)
  const [loading, setLoading] = useState(false)
  const [currentTrackUrl, setCurrentTrackUrl] = useState('')
  const EmbedControllerRef = useRef<IEmbedController | null>(null)
  const hasRun = useRef(false)

  const onPlaybackUpdate = useCallback(
    (data: PlaybackData) => {
      setLoading(false)
      setPlaybackData(data)
      if (data?.position === data?.duration) {
        onFullTrackListened?.({ ...data, currentTrackUrl })
      }
    },
    [currentTrackUrl, onFullTrackListened],
  )

  useEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true
      const element = document.getElementById('embed-iframe')
      if (element && !EmbedControllerRef.current) {
        ;(window as WindowType).onSpotifyIframeApiReady = (API: IFrameAPI) => {
          const options = {
            height: 0,
          }
          API.createController(
            element,
            options,
            (EmbedController: IEmbedController) => {
              EmbedControllerRef.current = EmbedController
            },
          )
        }
      }
    }
    return () => {
      EmbedControllerRef.current?.destroy()
    }
  }, [])

  const handleClick = (
    _: React.MouseEvent<HTMLDivElement>,
    trackUrl?: string,
  ): void => {
    if (EmbedControllerRef.current) {
      EmbedControllerRef.current.onPlaybackUpdate = onPlaybackUpdate
      if (currentTrackUrl === trackUrl) {
        if (!playbackData?.isPaused) {
          EmbedControllerRef.current.pause()
        } else {
          EmbedControllerRef.current.resume()
        }
      } else {
        setLoading(true)
        setCurrentTrackUrl(trackUrl ?? '')
        EmbedControllerRef.current.loadUri(`spotify:track:${trackUrl}`)
        EmbedControllerRef.current.play()
      }
    }
  }

  return (
    <>
      <div id='embed-iframe'></div>
      {render({
        currentTrackUrl,
        playbackData,
        isPlaying:
          !playbackData?.isPaused &&
          playbackData?.position !== playbackData?.duration,
        loading: Boolean(loading),
        onClick: handleClick,
      })}
    </>
  )
}
